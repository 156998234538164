<template>
  <v-text-field prepend-inner-icon="mdi-lock-outline" :placeholder="placeholder"
    :append-inner-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
    @click:append-inner="show = !show" rounded="lg"></v-text-field>
</template>
<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  placeholder?: string
}>()

let show = ref(false)
</script>
