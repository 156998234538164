import { createApp } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import * as Sentry from "@sentry/vue";

import AppDifficulty from '@/components/atoms/AppDifficulty.vue';
import AppDatePicker from '@/components/atoms/AppDatePicker.vue';
import AppDisplayField from '@/components/atoms/AppDisplayField.vue';
import AppGridCell from '@/components/atoms/AppGridCell.vue';
import CommentDrawer from "@/components/molecules/CommentDrawer.vue";
import CommentBtn from "@/components/atoms/CommentBtn.vue";
import AppTimePicker from "@/components/atoms/AppTimePicker.vue";

import AppBreadcrumb from "@/components/molecules/AppBreadcrumb.vue";
import AppLoading from "@/components/atoms/AppLoading.vue";
import AppTable from "@/components/molecules/AppTable.vue";
import AppTopBar from "@/components/molecules/AppTopBar.vue";
import ExportBtn from "@/components/molecules/ExportBtn.vue";
import AppFilterDropDown from "@/components/atoms/AppFilterDropDown.vue";
import AccountsTable from "@/components/molecules/AccountsTable.vue";
import AppViewComments from "@/components/molecules/AppViewComments.vue";
import AppPassword from '@/components/atoms/AppPassword.vue'
import AppStatsBar from "@/components/molecules/AppStatsBar.vue";
import AccountsList from "@/components/molecules/AccountsList.vue";
import SessionsTable from "@/components/molecules/SessionsTable.vue";

import AppConfirmDialog from "@/components/templates/AppConfirmDialog.vue";
import AppBanner from '@/components/templates/AppBanner.vue';

import App from '@/App.vue'

import router from "@/router";

import { vuetify } from "@/plugins/vuetify"
import { i18n } from '@/plugins/i18n'
import { apolloClient } from '@/plugins/apollo';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

declare module 'vue' {
  export interface GlobalComponents {
    AppBreadcrumb: typeof AppBreadcrumb;
    AppLoading: typeof AppLoading;
    AppTable: typeof AppTable;
    AppTopBar: typeof AppTopBar;
    ExportBtn: typeof ExportBtn;
    AppFilterDropDown: typeof AppFilterDropDown;
    AccountsTable: typeof AccountsTable;
    AppViewComments: typeof AppViewComments;
    AppPassword: typeof AppPassword;
    AppStatsBar: typeof AppStatsBar;
    AccountsList: typeof AccountsList;
    AppConfirmDialog: typeof AppConfirmDialog;
    AppDifficulty: typeof AppDifficulty;
    AppBanner: typeof AppBanner;
    AppDatePicker: typeof AppDatePicker;
    AppDisplayField: typeof AppDisplayField;
    AppGridCell: typeof AppGridCell;
    CommentDrawer: typeof CommentDrawer;
    CommentBtn: typeof CommentBtn;
    AppTimePicker: typeof AppTimePicker;
    SessionsTable: typeof SessionsTable;
  }
}

const app = createApp(App)

const CODEGEN_ADMIN_SECRET = import.meta.env.VITE_CODEGEN_ADMIN_SECRET as string | undefined

if (CODEGEN_ADMIN_SECRET) {
  Sentry.init({
    app,
    dsn: "https://0b9133a1e7ea7cd62d417b8f4648cbed@sentry.tools.pmw.io/2",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app
  .component('AppBreadcrumb', AppBreadcrumb)
  .component('AppLoading', AppLoading)
  .component('AppTable', AppTable)
  .component('AppTopBar', AppTopBar)
  .component('ExportBtn', ExportBtn)
  .component('AppFilterDropDown', AppFilterDropDown)
  .component('AccountsTable', AccountsTable)
  .component('AppViewComments', AppViewComments)
  .component('AppPassword', AppPassword)
  .component('AppStatsBar', AppStatsBar)
  .component('AccountsList', AccountsList)
  .component('AppConfirmDialog', AppConfirmDialog)
  .component('AppDifficulty', AppDifficulty)
  .component('AppBanner', AppBanner)
  .component('AppDatePicker', AppDatePicker)
  .component('AppDisplayField', AppDisplayField)
  .component('AppGridCell', AppGridCell)
  .component('CommentDrawer', CommentDrawer)
  .component('CommentBtn', CommentBtn)
  .component('AppTimePicker', AppTimePicker)
  .component('SessionsTable', SessionsTable)
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(vuetify)
  .provide(DefaultApolloClient, apolloClient)
  .mount('#app')
