export enum Role {
  ANONYMOUS = "anonymous",
  SUPER_ADMIN = "super_admin",
  CENTER = "center",
  DISTRIBUTOR = "distributor",
  MACHINE = "machine",
  OPERATOR = "operator",
  TECHNICIAN = "technician",
  PATIENT = "patient"
}

interface RoleConfig {
  dashboard: string;
}

export const roles: Record<Role, RoleConfig> = {
  "super_admin": {
    dashboard: "distributors",
  },
  "distributor": {
    dashboard: "centers",
  },
  "center": {
    dashboard: "operators",
  },
  "operator": {
    dashboard: "patients",
  },
  "technician": {
    dashboard: "machines",
  },
  "patient": {
    dashboard: "patient",
  },
  "anonymous": {
    dashboard: "login",
  },
  "machine": {
    dashboard: "login",
  },
}
