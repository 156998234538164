<template>
  <v-btn-icon icon="mdi-comment-edit-outline" class="text-caption" @click="drawer = true" />
  <comment-drawer v-model:is-open="drawer" :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)" :readonly="false" />
</template>
<script setup lang="ts">
import { ref } from "vue";

import { Comment } from "@/services/comments/models";

defineProps<{
  modelValue: Comment[]
}>();

defineEmits<{
  "update:modelValue": [Comment[]];
}>();

const drawer = ref(false);
</script>