<template>
  <TransitionGroup name="list" tag="p" class="toast-container">
    <AppToast v-for="tag, i in toastStore.toasts" :id="`toast-${i}`" class="list-item" :key="tag.uuid"
      :message="tag.message" :type="tag.type" />
  </TransitionGroup>
</template>
<script setup lang="ts">
import AppToast from '@/components/atoms/AppToast.vue';

import { useToastStore } from '@/store/toast';

const toastStore = useToastStore();
</script>
<style scoped lang="scss">
.toast-container {
  position: fixed;

  right: 50%;
  bottom: 0;
  transform: translateX(50%);

  z-index: 9999;

  display: flex;
  flex-direction: column;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
