<template>
  <app-confirm-dialog :title="$t('navbar.logout.title')" :text="$t('navbar.logout.text')"
    :confirm="$t('navbar.logout.confirm')" :cancel="$t('navbar.logout.cancel')" @confirm="authStore.logout()"
    v-model="dialog" @close="dialog = false">
    <NavbarEntry :text="$t('navbar.logout.title')" :selected="false" @click="dialog = true" :class="$props.class">
      <v-icon class="text-body-1">
        mdi-logout
      </v-icon>
    </NavbarEntry>
  </app-confirm-dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';

import NavbarEntry from '@/components/atoms/NavbarEntry.vue';

defineProps<{
  class?: string;
}>();

const authStore = useAuthStore();

const dialog = ref(false);
</script>
