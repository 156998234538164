import { stringify } from 'csv-stringify/browser/esm/sync';

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

export function exportTo(name: string, type: 'csv' | 'pdf', data: any[], columns: string[]) {
  if (type === 'csv') {
    exportToCSV(name, data, columns);
  } else {
    exportToPDF(name, data, columns);
  }
}

export function downloadFile(blob: Blob, fileName: string) {
  // Download the file
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  // Set the file name.
  link.download = fileName;
  link.click();
  link.remove();
}

export function exportToCSV(name: string, data: any[], columns: string[]) {
  const res = stringify(data, {
    header: true,
    columns,
  })

  const blob = new Blob([res], { type: 'text/csv' });
  downloadFile(blob, `${name}.csv`);
}

export function exportToPDF(name: string, data: any[], columns: string[]) {
  const doc = new jsPDF({ orientation: 'landscape' })

  autoTable(doc, {
    head: [columns],
    body: data,
    margin: { top: 5, bottom: 5, left: 5, right: 5 },
  })

  downloadFile(doc.output('blob'), `${name}.pdf`);
}

