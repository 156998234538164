import { graphql } from "@/gql";

export const SUBSCRIBE_ACTION_REPORTING = graphql(`
subscription SubscribeActionReporting(
    $where: action_reporting_bool_exp!
    $order_by: [action_reporting_order_by!]
    $offset: Int!
    $limit: Int!
) {
    action_reporting(
        where: $where
        order_by: $order_by
        offset: $offset
        limit: $limit
    ) {
      action
      action_role
      data
      id
      record_name
      timestamp
      user_name
    }
}
`)

export const SUBSCRIBE_ACTION_REPORTING_AGGREGATE = graphql(`
subscription SubscribeActionReportingAggregate($where: action_reporting_bool_exp!) {
    action_reporting_aggregate(where: $where) {
        aggregate {
            count
        }
    }
}
`)

export const LOG_LOGOUT = graphql(`
mutation LogLogout(
  $email: String!
  $name: String!
) {
  insert_event_log_one(object: {
    account_email: $email
    account_name: $name
    type: "LOGOUT"
  }) {
    id
  }
}
`);

export const LOG_LOGIN = graphql(`
mutation LogLogin(
  $email: String!
  $name: String!
) {
  insert_event_log_one(object: {
    account_email: $email
    account_name: $name
    type: "LOGIN"
  }) {
    id
  }
}
`);

export const LOG_EXPORT = graphql(`
mutation LogExport(
  $email: String!
  $name: String!
  $actionRole: String!
) {
  insert_event_log_one(object: {
    account_email: $email
    account_name: $name
    action_role: $actionRole
    type: "EXPORT"
  }) {
    id
  }
}
`);