import { Operator } from './models';
import { Job_Enum, OperatorFragment, UserFieldsFragment } from '@/gql/graphql';

export function mapOperators(data?: OperatorFragment[] | null): Operator[] {
  if (!data) throw new Error('Operators data is null')

  return data.map(mapOperator)
}

export function mapOperator(data: OperatorFragment | null): Operator {
  if (!data) throw new Error('Operator data is null')

  const account = data.accounts?.user as UserFieldsFragment | null
  if (!account) throw new Error('Operator account is null')

  return {
    id: data.id,
    user_id: account.id,
    firstname: account.account?.firstname || '',
    lastname: account.account?.lastname || '',
    center_name: data.center.name || '',
    center_id: data.center.id,
    job: data.job || Job_Enum.BiomedicalTechnician,
    email: account?.email,
    phone: account?.phoneNumber || '',
    secondary_email: data.secondary_email || null,
  }
}
