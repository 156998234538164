<template>
  <div>
    <v-menu :close-on-content-click="false" :offset="24">
      <template v-slot:activator="{ props }">
        <v-btn-top-bar v-bind="props" append-icon="mdi-clock-outline" rounded="0" class="rounded-s-lg">
          {{ modelValue ?? title }}
        </v-btn-top-bar>
        <v-btn-top-bar icon="mdi-close-circle-outline" density="comfortable" rounded="0" class="rounded-e-lg"
          @click="clear">
        </v-btn-top-bar>
      </template>
      <div class=" pa-0 ma-0 rounded-lg bg-background-700 elevation-2 date-container">
        <v-time-picker class="bg-background-700" :format="format" :ampm-in-title="format === 'ampm'"
          :model-value="modelValue" @update:model-value="emit('update:modelValue', $event)" title="" />
      </div>
    </v-menu>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from '@/plugins/i18n';
import { computed } from 'vue';

defineProps<{
  modelValue: string | null
  title: string
}>()

const { t } = useI18n()

const format = computed(() => t('timepicker.format') as "ampm" | "24hr")

function clear() {
  emit('update:modelValue', null)
}

const emit = defineEmits<{
  "update:modelValue": [string | null]
}>()
</script>
<style scoped lang="scss">
.date-container {
  display: flex;
  flex-direction: column;

  .date-range {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .date-picker {
    padding: 16px;
  }
}
</style>