import { useFragment } from '@/gql'
import { Patient } from './models'
import { MachineFragmentDoc, PatientFragment, UserFieldsFragment } from '@/gql/graphql'
import { formatName } from '@/utils/utils'

export function mapPatients(data?: PatientFragment[] | null): Patient[] {
  if (!data) throw new Error('Patients data is null')
  return data.map(mapPatient)
}

export function mapPatient(data?: PatientFragment | null): Patient {
  if (!data) throw new Error('Patient data is null')
  const account = data.accounts?.user as UserFieldsFragment
  const machine = useFragment(MachineFragmentDoc, data.machine)

  return {
    id: data.id,
    user_id: account?.id,
    identifier: data.identifier || '',
    firstname: account?.account?.firstname || '',
    lastname:  account?.account?.lastname || '',
    machine_id: machine?.id,
    machine_name: machine?.identifier || '',
    center_identifier: data.center.identifier || '',
    center_name: data.center.name || '',
    email: account?.email,
    phone: account?.phoneNumber || '',
    operator_ids: data.patient_operator.map((o) => o.operator?.id),
    operator_names: data.patient_operator.map((o) => 
      formatName(`${o?.operator?.accounts?.firstname ?? ''} ${o?.operator?.accounts?.lastname ?? ''}`, o.operator?.identifier ?? '')
    ),
    center_id: data.center.id || '',
    birth_date: new Date(data.birth_date),
    accept_data_transmission: data.accept_data_transmission,
  }
}
