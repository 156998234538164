import { graphql } from "@/gql";

export const QUERY_DISTRIBUTOR_INFOS = graphql(`
query QueryDistributorInfos(
  $id: uuid!
  $where: center_bool_exp = {}
  $order_by: [center_order_by!]!
  $limit: Int!
  $offset: Int!
) {
  distributor_by_pk(id: $id) {
    ...Distributor
    centers(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      ...Center
    }
    primary_account {
      user {
        ...UserFields
      }
    }
    accounts {
      user {
        ...UserFields
      }
    }
    centers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  
  center_aggregate(where: {distributor_id: {_eq: $id}}) {
    aggregate {
      count
    }
  }

  machine_aggregate(where: {center: {distributor_id: {_eq: $id}}}) {
    aggregate {
      count
    }
  }

  patient_aggregate(where: {center: {distributor_id: {_eq: $id}}}) {
    aggregate {
      count
    }
  }
}
`)

export const QUERY_DISTRIBUTOR = graphql(`
query QueryDistributor($id: uuid!) {
  distributor_by_pk(id: $id) {
    ...Distributor
    primary_account {
      user {
        ...UserFields
      }
    }
    accounts {
      user {
        ...UserFields
      }
    }
  }
}
`)

export const UPDATE_DISTRIBUTOR = graphql(`
mutation UpdateDistributor(
  $id: uuid!
  $name: String!
  $address: String!
  $geographicZones: [String!]!
  $comments: String!

  $usersToAdd: [users_insert_input!]!
  $usersToUpdate: [users_updates!]!
  $accountsToAdd: [account_insert_input!]!
  $accountsToUpdate: [account_updates!]!
  $accountsToDelete: [uuid!]!
  
  $defaultCenterId: uuid
  $primaryAccountId: uuid!
) {
  # INSERT users and accounts
  insertUsers(
    objects: $usersToAdd
  ) {
    affected_rows
  }

  insert_account(
    objects: $accountsToAdd
  ) {
    affected_rows
  }

  # UPDATE PRIMARY ACCOUNT
  update_primary_account: update_distributor_by_pk(
    pk_columns: {
      id: $id
    }
    _set: {
      primary_account_id: $primaryAccountId
    }
  ) {
    id
  }

  # UPDATE users and accounts
  update_account_many(
    updates: $accountsToUpdate
  ) {
    affected_rows
  }

  update_users_many(
    updates: $usersToUpdate
  ) {
    affected_rows
  }

  # DELETE users and accounts

  delete_account(where: { _and: [
    { user_id: {_in: $accountsToDelete}}
    { distributor_id: {_eq: $id}}
  ]}) {
    affected_rows
  }

  # FINALLY UPDATE DISTRIBUTOR
  update_distributor_by_pk(
    pk_columns: {
      id: $id
    }
    _set: {
      company_name: $name
      address: $address
      geographic_zones: $geographicZones
      comments: $comments
      default_center_id: $defaultCenterId
    }
  ) {
    id
    ...Distributor
    accounts {
      id
      user {
        ...UserFields
      }
    }
  }
}
`)

export const SUBSCRIBE_DISTRIBUTORS = graphql(`
subscription SubscribeDistributors(
  $where: distributor_bool_exp = {},
  $order_by: [distributor_order_by!]!
  $limit: Int!
  $offset: Int!
) {
  distributor(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
    ...Distributor
    primary_account {
      user {
        ...UserFields
      }
    }
    accounts {
      user {
        ...UserFields
      }
    }
  }
}
`)

export const SUBSCRIBE_DISTRIBUTOR_COUNT = graphql(`
subscription SubscribeDistributorsCount($where: distributor_bool_exp = {}) {
  distributor_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
`)

export const QUERY_DISTRIBUTORS_LIST = graphql(`
query QueryDistributorsList {
  distributor {
    id
    company_name
    identifier
  }
}
`)

export const QUERY_ALL_DISTRIBUTORS = graphql(`
query QueryAllDistributors($where: distributor_bool_exp = {}) {
  distributor(where: $where) {
    ...Distributor
    primary_account {
      user {
        ...UserFields
      }
    }
    accounts {
      user {
        ...UserFields
      }
    }
  }
}
`)

export const UPDATE_COMMENTS = graphql(`
mutation UpdateDistributorComments($id: uuid!, $comments: String!) {
  update_distributor_by_pk(pk_columns: {id: $id}, _set: {comments: $comments}) {
    id
  }
}
`)

export const ADD_DISTRIBUTOR = graphql(`
mutation AddDistributor(
  $id: uuid!
  $name: String!
  $address: String!
  $geographicZones: [String!]!
  $comments: String!
  $users: [users_insert_input!]!
  $accounts: [account_insert_input!]!
  $primaryAccountId: uuid!
  $defaultCenterId: uuid
) {
  insertUsers(objects: $users) {
    affected_rows
  }

  insert_distributor_one(
    object: {
      id: $id
      company_name: $name
      address: $address
      comments: $comments
      geographic_zones: $geographicZones
      default_center_id: $defaultCenterId
    }
  ) {
    ...Distributor
  }

  insert_account(objects: $accounts) {
    affected_rows
  }

  update_distributor_by_pk(
    pk_columns: {
      id: $id
    }
    _set: {
      primary_account_id: $primaryAccountId
    }
  ) {
    ...Distributor
  }
}
`)
