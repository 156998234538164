
export function trimmedField(field: string | null) {
    if (!field) return "";
    return field.length > 20 ? field.substring(0, 20) + "..." : field;
}

export function formatName(name: string, identifier: string | undefined) {
    let x = name.trim();

    if (x === "") {
        x = identifier ?? '<NO IDENTIFIER FOUND>';
    } else {
        x += ` (${identifier})`;
    }

    return x;
}