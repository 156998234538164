import { CenterFragment, UserFieldsFragmentDoc } from '@/gql/graphql';

import { Center } from './models';

import { mapUser } from '@/store/user/mappers';
import { mapComments } from '@/services/comments/mappers';
import { useFragment } from '@/gql';


export function mapCenters(data: CenterFragment[] | null): Center[] {
  if (!data) throw new Error('Centers data is null')
  return data.map(mapCenter)
}

export function mapCenter(data?: CenterFragment | null): Center {
  if (!data) throw new Error('Center data is null')

  const primaryAccount = useFragment(UserFieldsFragmentDoc, data.primary_account?.user ?? data.accounts[0]?.user ?? null)
  if (!primaryAccount) throw new Error('Primary account is null')

  return {
    id: data.id,
    name: data.name || '',
    identifier: data.identifier,
    address: data.address,
    distributorIdentifier: data.distributor?.identifier,
    country: data.country,
    comments: mapComments(data.comments),
    users: data.accounts.map(a => a.user)
                        .map(u => useFragment(UserFieldsFragmentDoc, u))
                        .map(mapUser),
    primaryAccount: mapUser(primaryAccount),
    distributorId: data.distributor_id,
    allowedZones: data.distributor.geographic_zones.filter(e => e.length === 2) || [],
  }
}
