import {ref, watch} from "vue";
import {defineStore} from "pinia";
import {provideApolloClient, useMutation, useSubscription} from '@vue/apollo-composable';

import {apolloClient} from "@/plugins/apollo";
import {User} from "./models";
import {DELETE_USER, SUBSCRIBE_CENTER, SUBSCRIBE_DISTRIBUTOR, SUBSCRIBE_USER, SUBSCRIBE_OPERATOR, SUBSCRIBE_PATIENT, SUBSCRIBE_TECHNICIAN, UPDATE_USER} from "./queries";
import {mapUser} from "./mappers";
import {CenterFragment, DistributorFragment, OperatorFragment, PatientFragment, TechnicianFragment, UserFieldsFragment} from "@/gql/graphql";
import {useI18n} from "@/plugins/i18n";
import {Role} from "@/store/auth/models.ts";
import {mapDistributor} from "@/services/distributors/mappers.ts";
import {mapOperator} from "@/services/operators/mappers.ts";
import {mapCenter} from "@/services/centers/mappers.ts";
import {mapTechnician} from "@/services/technicians/mappers.ts";
import {mapPatient} from "@/services/patients/mappers.ts";

provideApolloClient(apolloClient);

function subscribeUser(id: string, role: string, callback: (user: User) => void) {
  const errorCallback = (err: any) => {
    console.error(err)
  }

  switch (role) {
    case Role.SUPER_ADMIN: {
      const { onResult, loading, onError } = useSubscription(SUBSCRIBE_USER, { id })
      onResult((res) => {
        if (loading.value) return

        let user = mapUser(res?.data?.user as UserFieldsFragment)
        callback(user)
      })
      onError(errorCallback)
      return;
    }
    case Role.PATIENT: {
      const {onResult, loading, onError} = useSubscription(SUBSCRIBE_PATIENT, { id })
      onResult((res) => {
        if (loading.value) return

        let user = mapUser(res?.data?.user as UserFieldsFragment)
        user.account.patient = mapPatient(res?.data?.user?.account?.patient as PatientFragment) ?? null
        callback(user)
      })
      onError(errorCallback)
      return;
    }
    case Role.DISTRIBUTOR: {
      const {onResult, loading, onError} = useSubscription(SUBSCRIBE_DISTRIBUTOR, { id })
      onResult((res) => {
        if (loading.value) return

        let user = mapUser(res?.data?.user as UserFieldsFragment)
        user.account.distributor = mapDistributor(res?.data?.user?.account?.distributor as DistributorFragment) ?? null
        callback(user)
      })
      onError(errorCallback)
      return;
    }
    case Role.CENTER: {
      const {onResult, loading, onError} = useSubscription(SUBSCRIBE_CENTER, { id })
      onResult((res) => {
        if (loading.value) return

        let user = mapUser(res?.data?.user as UserFieldsFragment)
        user.account.center = mapCenter(res?.data?.user?.account?.center as CenterFragment) ?? null
        callback(user)
      })
      onError(errorCallback)
      return;
    }
    case Role.OPERATOR: {
      const {onResult, loading, onError} = useSubscription(SUBSCRIBE_OPERATOR, { id })
      onResult((res) => {
        if (loading.value) return

        let user = mapUser(res?.data?.user as UserFieldsFragment)
        user.account.operator = mapOperator(res?.data?.user?.account?.operator as OperatorFragment) ?? null
        callback(user)
      })
      onError(errorCallback)
      return;
    }
    case Role.TECHNICIAN: {
      const {onResult, loading, onError} = useSubscription(SUBSCRIBE_TECHNICIAN, { id })
      onResult((res) => {
        if (loading.value) return

        let user = mapUser(res?.data?.user as UserFieldsFragment)
        user.account.technician = mapTechnician(res?.data?.user?.account?.technician as TechnicianFragment) ?? null
        callback(user)
      })
      onError(errorCallback)
      return;
    }
    default:
      throw new Error("Invalid role " + role)
  }
}

async function updateUser(id: string, firstname: string, lastname: string, pseudo: string, phone: string, locale: string): Promise<void> {
  const {mutate, onError, onDone} = useMutation(UPDATE_USER)

  return new Promise((resolve, reject) => {
    onError((err: any) => {
      reject(err)
    })
    onDone(() => {
      resolve()
    })

    mutate({
      id,
      firstname,
      lastname,
      pseudo,
      phone,
      locale,
    })
  })
}

async function deleteUser(accountId: string): Promise<void> {
  const {mutate, onError, onDone} = useMutation(DELETE_USER)
  return new Promise((resolve, reject) => {
    onError((err: any) => {
      reject(err)
    })
    onDone(() => {
      resolve()
    })
    mutate({
      accountId,
    })
  })
}

export const useUserStore = defineStore("user", () => {
  const {locale} = useI18n()

  const currentUser = ref<User | null>(null)

  function setUser(id: string, userRole: string) {
    subscribeUser(id, userRole, (user) => {
      currentUser.value = user
      locale.value = user.language
    })
  }

  function clearUser() {
    currentUser.value = null
  }

  function watchUser(callback: (user: User | null) => void) {
    watch(currentUser, (user) => {
        callback(user)
    }, { immediate: true })
  }

  async function waitForUser() {
    return new Promise<User>((resolve) => {
      watchUser((user) => {
        if (user) {
          resolve(user)
        }
      })
    })
  }

  return {
    clearUser,
    updateUser,
    deleteUser,
    setUser,
    watchUser,
    waitForUser,
  }
});

