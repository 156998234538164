<template>
  <div class="bg-background-500 rounded-lg pa-2">
    <div class=" d-flex justify-space-between">
      <p class="text-typography-700">{{ $d(comment.date, 'short') }}</p>
      <app-confirm-dialog :title="$t('comments.delete-title')" :text="$t('comments.delete-confirm')" v-if="!readonly"
        :cancel="$t('common.cancel')" :confirm="$t('common.delete')" @confirm="$emit('delete')" v-model="deleteDialog"
        @close="deleteDialog = false">
        <v-btn-icon @click="deleteDialog = true">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn-icon>
      </app-confirm-dialog>
    </div>
    <div ref="viewRef"></div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import { Comment } from "@/services/comments/models";

const props = defineProps<{
  comment: Comment;
  readonly?: boolean;
}>();

defineEmits<{
  delete: [];
}>();

const deleteDialog = ref(false);

const viewRef = ref<HTMLElement>();
const view = ref<Quill>();

onMounted(() => {
  if (!viewRef.value) return;

  view.value = new Quill(viewRef.value, {
    theme: "bubble",
  });

  view.value.root.innerHTML = props.comment.content
  view.value.enable(false)
})
</script>
<style lang="scss">
$background-color: rgb(var(--v-theme-background-500));
$text-color: rgb(var(--v-theme-typography-900));

.ql-container.ql-bubble {
  border: none;

  .ql-view {
    color: $text-color;
  }
}
</style>
@/services/comments/models