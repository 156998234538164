import { UserFieldsFragment } from "@/gql/graphql";
import { User } from "./models";
import { Role } from "@/store/auth/models";

export function mapUser(data?: Partial<UserFieldsFragment> | null): User {
  if (!data) throw new Error("User data is null");

  return {
    accountId: data.account?.id,
    email: data.email,
    phone: data.phoneNumber || "",
    language: data.locale || "",
    firstname: data.account?.firstname || "",
    lastname: data.account?.lastname || "",
    pseudo: data.displayName || "",
    id: data.id,
    defaultRole: data.defaultRole as Role,
    disabled: data.disabled || false,

    account: {
      distributor: null,
      operator: null,
      patient: null,
      technician: null,
      center: null,
    },
  };
}
