<template>
  <td class="pr-2">
    <v-text-field :placeholder="$t('accounts-table.field.firstname-placeholder')" v-model="modelValue.firstname"
      :error-messages="accountErrors.firstname" />
  </td>
  <td class="pr-2">
    <v-text-field :placeholder="$t('accounts-table.field.lastname-placeholder')" v-model="modelValue.lastname"
      :error-messages="accountErrors.lastname" />
  </td>
  <td class="pr-2">
    <v-text-field :placeholder="$t('accounts-table.field.phone-number-placeholder')" v-model="modelValue.phone"
      :error-messages="accountErrors.phone" />
  </td>
  <td class="pr-2">
    <v-text-field :placeholder="$t('accounts-table.field.email-placeholder')" v-model="modelValue.email"
      :error-messages="accountErrors.email" />
  </td>
  <td class="pr-2 text-no-wrap">
    <v-select :model-value="!modelValue.disabled" @update:model-value="modelValue.disabled = !$event" :items="statues"
      :style="{ width: 'max-content' }" />
  </td>
  <td class="pr-2 w-0 pb-6">
    <v-radio :model-value="isPrimary" @click="emit('update:isPrimary', true)" />
  </td>
  <td class="w-0 pb-6">
    <v-btn-primary prepend-icon="mdi-mail" class="text-caption" @click="authStore.resendInvite(modelValue.id)">
      {{ $t('common.resend-invite') }}
    </v-btn-primary>
  </td>
  <td class="w-0 pb-6">
    <app-confirm-dialog :title="$t('accounts-table.delete.title')" :text="$t('accounts-table.delete.text')"
      :confirm="$t('accounts-table.delete.confirm')" :cancel="$t('accounts-table.delete.cancel')" @confirm="del"
      v-model="dialog" @close="dialog = false">
      <v-btn-icon icon="mdi-trash-can-outline" class="text-caption" @click="dialog = true" />
    </app-confirm-dialog>
  </td>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { UserForm, UserError } from '@/store/user/models';
import { useDistributors } from '@/services/distributors/index';
import { useAuthStore } from '@/store/auth';

const { useStatus } = useDistributors()
const { statues } = useStatus()

const dialog = ref(false)

const authStore = useAuthStore()

defineProps<{
  modelValue: UserForm
  isPrimary: boolean
  accountErrors: UserError
}>()

const emit = defineEmits<{
  'update:isPrimary': [boolean]
  'delete': []
}>()

async function del() {
  emit('delete')
  dialog.value = false
}
</script>
