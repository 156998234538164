import {useAuthStore} from '@/store/auth'
import {ApolloClient, HttpLink, InMemoryCache, split} from "@apollo/client/core"
import {setContext} from '@apollo/client/link/context';
import {RetryLink} from '@apollo/client/link/retry';
import {getMainDefinition} from '@apollo/client/utilities';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {createClient} from 'graphql-ws';

export interface HasuraError {
  message: string
  extensions: {
    path: string
    code: string
  }
}

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_HASURA_WSS_URL,
    lazy: true,
    connectionParams: async () => {
      const baseArgs = {
        reconnect: true,
        timeout: 30000,
      }

      const authStore = useAuthStore()
      if (!authStore.isSignedIn) return baseArgs

      const token = authStore.accessToken

      return {
        ...baseArgs,
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    },
  })
)

const authLink = setContext((_, {headers}) => {
  const authStore = useAuthStore()

  const isSignedIn = authStore.isSignedIn
  if (!isSignedIn) return headers

  const token = authStore.accessToken
  if (!token) return headers

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const retryLink = new RetryLink({attempts: {max: 3}});


const httpLink = new HttpLink({
  uri: import.meta.env.VITE_HASURA_GRAPHQL_URL,
});


const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const apolloClient = new ApolloClient({
  link: authLink.concat(retryLink).concat(splitLink),
  cache: new InMemoryCache({
    typePolicies: {
      VersionResult: {
        keyFields: ["versionNumber"],
      },
    },
  }),
})
