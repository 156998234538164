import { computed } from "vue"
import { useMutation, useSubscription } from "@vue/apollo-composable"
import { Action_Reporting_Bool_Exp, Action_Reporting_Order_By } from "@/gql/graphql"

import { usePaginatedQueryArgs, usePagination } from "@/utils/filters"
import { useActionReportingFilters } from "./filters"
import { LOG_EXPORT, LOG_LOGIN, LOG_LOGOUT, SUBSCRIBE_ACTION_REPORTING, SUBSCRIBE_ACTION_REPORTING_AGGREGATE } from "./queries"
import { useUserStore } from "@/store/user"
import { useI18n } from "@/plugins/i18n"

export function useActionReporting() {
    const { filters, columnOrders } = useActionReportingFilters()
    const { t } = useI18n()

    interface EditionSummary {
        key: string
        old_value: string
        new_value: string
    }

    function dataToJson(data: string): EditionSummary[] {
      try {
        return JSON.parse(data)
      } catch (e) {
        return []
      }
    }

    function subscribeActionReporting() {
        const pagination = usePagination()

        const args = usePaginatedQueryArgs<Action_Reporting_Bool_Exp, Action_Reporting_Order_By>(filters, columnOrders, pagination)
        const { result, loading } = useSubscription(SUBSCRIBE_ACTION_REPORTING, args)

        const { result: resultAggregate, loading: aggregateLoading } = useSubscription(SUBSCRIBE_ACTION_REPORTING_AGGREGATE, computed(() => ({
            where: filters.value
        })))

        const actionReportings = computed(() => (result.value?.action_reporting || []).map(ar => ({
            id: ar.id,
            timestamp: new Date(ar.timestamp),
            action: t(`action-reporting.type.${ar.action.toLowerCase()}`),
            initiator: ar.user_name,
            ressource: (ar.action_role ? t(`tables.${ar.action_role}`) : ''),
            ressource_identifier: ar.record_name,
            data: dataToJson(ar.data ?? ''),
        })))

        const total = computed(() => resultAggregate.value?.action_reporting_aggregate.aggregate?.count ?? 0)

        return {
            actionReportings,
            loading: computed(() => loading.value || aggregateLoading.value),
            columnOrders,
            page: pagination.page,
            limit: pagination.limit,
            total,
        }
    }

    async function logLogin() {
        const { waitForUser } = useUserStore()
        const user = await waitForUser()

        const { mutate } = useMutation(LOG_LOGIN)

        await mutate({
            email: user.email,
            name: `${user.firstname} ${user.lastname}`,
        })
    }

    async function logLogout() {
        const { mutate } = useMutation(LOG_LOGOUT)
        const { waitForUser } = useUserStore()
        const user = await waitForUser()

        await mutate({
            email: user.email,
            name: user.firstname + " " + user.lastname,
        })
    }

    async function logExport(table: string) {
        const { mutate } = useMutation(LOG_EXPORT)
        const { waitForUser } = useUserStore()
        const user = await waitForUser()

        await mutate({
            email: user.email,
            name: user.firstname + " " + user.lastname,
            actionRole: table,
        })
    }

    return {
        subscribeActionReporting,
        logLogin,
        logLogout,
        logExport,
    }
}
