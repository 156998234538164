<template>
  <div class="d-inline-flex flex-row rounded-lg bg-background-700 pa-2">
    <div v-for="(stat, i) in stats" :class="classes(stat, i)" :key="stat.title"
      @click="stat.click ? stat?.click() : ''">
      {{ stat.title }}: {{ stat.value }}
      <v-icon class="text-body-1" v-if="stat.click">mdi-link-variant</v-icon>
    </div>
  </div>
</template>
<script setup lang="ts">

interface Stat {
  title: string
  value: number
  click?: () => void
}

const props = defineProps<{
  stats: Stat[]
}>()

const paddingBottom = (i: number) => i === props.stats.length - 1 ? '' : 'mr-3'

const clickable = (stat: Stat) => stat.click ? 'pointer' : ''

const classes = (stat: Stat, i: number) => `pa-3 px-5 bg-background-500 rounded ${paddingBottom(i)} ${clickable(stat)}`
</script>
<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>
