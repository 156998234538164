import { useI18n } from "@/plugins/i18n"

export function formatDate(date: Date) {
    const { t } = useI18n()
    const lang = t('date.format.lang') as Intl.LocalesArgument
    const year = t('date.format.year')
    const month = t('date.format.month')
    const day = t('date.format.day')

    return date.toLocaleDateString(lang, { year, month, day } as Intl.DateTimeFormatOptions)
}

export function formatHour(date: Date) {
    const { t } = useI18n()
    const lang = t('date.format.lang') as Intl.LocalesArgument
    const hour = t('date.format.hour')
    const minute = t('date.format.minute')

    return date.toLocaleTimeString(lang, { hour, minute } as Intl.DateTimeFormatOptions)
}

export function parseDate(date: string): Date | null {
    const { t } = useI18n()

    const regex = new RegExp(t('date.parse.regex'))
    if (!(regex.test(date))) {
        return null
    }

    const format = t('date.parse.format')
    const enFormat = date.replace(regex, format)

    return new Date(enFormat)
}
