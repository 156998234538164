import { watch } from "vue";
import { I18nOptions, createI18n } from "vue-i18n";

import fr from "@/locales/fr.json";
import en from "@/locales/en.json";
import { toRef } from "vue";
import { useStorage } from "@vueuse/core";

const datetimeFormats: I18nOptions["datetimeFormats"] = {
  'en': {
    short: {
      year: 'numeric', month: '2-digit', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'fr': {
    short: {
      year: 'numeric', month: '2-digit', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
}

const languages = [
  { value: 'en', title: 'English' },
  { value: 'fr', title: 'Français' },
]

const language = useStorage('language', 'en')

export const i18n = createI18n({
  lecacy: false,
  locale: language.value,
  datetimeFormats,
  silentTranslationWarn: true,
  messages: {
    fr: fr,
    en: en,
  },
});

export const useI18n = () => {
  const t = i18n.global.t.bind(i18n)
  const tc = i18n.global.tc.bind(i18n)
  const d = i18n.global.d.bind(i18n)
  const locale = toRef(i18n.global, 'locale')

  watch(locale, (val) => {
    language.value = val
  }, { immediate: true })

  return {
    t,
    tc,
    d,
    locale,
    languages,
  }
}
