<template>
  <div>
    <v-menu :close-on-content-click="false" :offset="24">
      <template v-slot:activator="{ props }">
        <v-btn-top-bar v-bind="props" append-icon="mdi-calendar-blank-outline" rounded="0" class="rounded-s-lg">
          {{ modelValue.length != 0 ? formatedDate : $t('common.date-filter') }}
        </v-btn-top-bar>
        <v-btn-top-bar icon="mdi-close-circle-outline" density="comfortable" rounded="0" class="rounded-e-lg"
          @click="clear">
        </v-btn-top-bar>
      </template>
      <div class="d-flex flex-row pa-0 ma-0 rounded-lg bg-background-700 elevation-2">
        <v-date-picker color="primary-900" multiple='range' v-model="rawDateFilter" />
      </div>
    </v-menu>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import dayjs from 'dayjs';

const props = defineProps<{
  modelValue: string[]
}>()

const emit = defineEmits<{
  "update:modelValue": [string[]]
}>()

function clear() {
  emit('update:modelValue', [])
}

const formatedDate = computed(() => {
  if (dateFilter.value.length === 0) return "";
  if (dateFilter.value.length === 1) return dayjs(dateFilter.value[0]).format("DD/MM/YYYY");

  return `${dayjs(dateFilter.value[0]).format("DD/MM/YYYY")} - ${dayjs(dateFilter.value[1]).format("DD/MM/YYYY")}`;
});

const rawDateFilter = ref<string[]>(props.modelValue);
const dateFilter = computed(() => {
  if (rawDateFilter.value.length === 0) return [];
  if (rawDateFilter.value.length === 1) return rawDateFilter.value;

  return [
    rawDateFilter.value[0],
    rawDateFilter.value[rawDateFilter.value.length - 1]
  ]
});

watch(dateFilter, (newVal) => {
  emit("update:modelValue", newVal);
});
</script>
