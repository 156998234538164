import { graphql } from "@/gql";

export const LOGIN = graphql(`
mutation Login($email: String!, $password: String!) {
  login(data: {email: $email, password: $password}) {
    error
  }
}
`)

export const SEND_PINCODE = graphql(`
mutation SendPincode($email: String!, $password: String!, $pincode: String!) {
  sendPincode(data: {email: $email, password: $password, pincode: $pincode}) {
    error
    accessToken
    refreshToken
  }
}
`)

export const ASK_FOR_DELETION = graphql(`
mutation AskForDeletion($id: uuid!) {
  update_account_by_pk(pk_columns: {id: $id}, _set: {asked_for_deletion: true}) {
    id
  }
  
  updateUser(pk_columns: {id: $id}, _set: {disabled: true}) {
    id
  }
}
`)

export const RESEND_INVITE = graphql(`
mutation ResendInvite($userId: String!) {
  resendInvitationEmail(userId: $userId)
}
`)
