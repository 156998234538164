import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import privateRoutes from './routes/private';
import publicRoutes from "./routes/public";
import { useAuthStore } from "@/store/auth";

const handleRoute = async (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authStore = useAuthStore();

  await authStore.ensureAuthLoaded();

  if (!authStore.isSignedIn && to.meta.priv) {
    // Redirect to sign in page if user is not signed in and tries to access private page
    next({ path: "/sign-in", replace: true })
  } else if (["/sign-in"].includes(to.path) && authStore.isSignedIn) {
    // Redirect to home page if user is signed in and tries to access sign in page
    next({ path: "/", replace: true });
  }

  next();
};

function addMetaPrivate(route: RouteRecordRaw, priv: boolean): RouteRecordRaw {
  return {
    ...route,
    meta: {
      ...route.meta,
      priv,
    }
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...privateRoutes.map(e => addMetaPrivate(e, true)),
    ...publicRoutes.map(e => addMetaPrivate(e, false)),
  ],
})

router.beforeEach((to, from, next) => {
  handleRoute(to, from, next);
});

export default router;

