<template>
  <v-navigation-drawer :model-value="isOpen" @update:model-value="emit('update:isOpen', $event)" :scrim="false"
    location="right" temporary class="pa-8 w-25" :order="0">
    <div class=" pb-8 d-flex justify-space-between align-center">
      <h2>{{ $t('comments.title') }}</h2>
      <v-btn-background-link density="compact" @click="emit('update:isOpen', false)" class="ml-auto">
        {{ $t('common.close') }}
      </v-btn-background-link>
    </div>
    <TextEditor v-model="textInput" class="mb-4" v-if="!readonly" />
    <v-btn-primary @click="addComment" class="w-100" v-if="!readonly">
      {{ $t('common.add') }}
    </v-btn-primary>
    <v-divider class="my-4" v-if="!readonly"></v-divider>
    <TextView :comment="co" :readonly="readonly" @delete="deleteComment(co.id)"
      :class="index !== modelValue.length - 1 ? 'mb-4' : ''" v-for="(co, index) in modelValue" :key="co.id" />
  </v-navigation-drawer>
</template>
<script setup lang="ts">
import { ref, toRef, watch } from "vue";
import { v4 as uuidv4 } from "uuid";

import TextEditor from "@/components/atoms/TextEditor.vue";
import TextView from "@/components/atoms/TextView.vue";

import { Comment } from "@/services/comments/models";
import { useOverlayStore } from "@/store/overlay";
const overlayStore = useOverlayStore();

const props = defineProps<{
  modelValue: Comment[];
  readonly: boolean;
  isOpen: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [Comment[]];
  "update:isOpen": [boolean];
}>();

const textInput = ref<string>("");

function addComment() {
  if (textInput.value === "") {
    return;
  }

  const newComment: Comment = {
    id: uuidv4(),
    date: new Date(),
    content: textInput.value,
  };

  emit("update:modelValue", [...props.modelValue, newComment]);
  textInput.value = "";
}

function deleteComment(id: string) {
  emit("update:modelValue", props.modelValue.filter((c) => c.id !== id));
}

const isOpen = toRef(props, "isOpen");

watch(isOpen, (value) => {
  overlayStore.enable = value;
}, { immediate: true });

overlayStore.onClose(() => {
  emit("update:isOpen", false);
});
</script>
