import { Distributor, DistributorStats } from "./models";
import { mapUser } from "@/store/user/mappers";
import { DistributorFragment, QueryDistributorInfosQuery, UserFieldsFragmentDoc } from "@/gql/graphql";
import { mapComments } from '@/services/comments/mappers';
import { useFragment } from "@/gql";

export const mapDistributor = (data: DistributorFragment | null): Distributor => {
  if (!data) throw new Error('Distributor data is null')

  const user = useFragment(UserFieldsFragmentDoc, data.primary_account?.user ?? data.accounts[0]?.user)
  if (!user) console.error("Primary account is null for distributor " + data.id)
  const primaryAccount = (!user) ? null : mapUser(user)

  return {
    id: data.id,
    address: data.address,
    companyName: data.company_name || '',
    geographicZones: data.geographic_zones.filter(e => e.length === 2),
    comments: mapComments(data.comments),
    identifier: data.identifier,
    users: data.accounts.map(a => a.user)
                        .map(a => useFragment(UserFieldsFragmentDoc, a))
                        .map(mapUser),
    primaryAccount: primaryAccount,
    defaultCenterId: data.default_center?.id || null,
    defaultCenterName: data.default_center?.name || null,
  }
}

export function mapDistributors(data: DistributorFragment[] | null): Distributor[] {
  if (!data) throw new Error('Distributors data is null')
  return data.map(mapDistributor)
}

export function mapDistributorStats(data: QueryDistributorInfosQuery | null): DistributorStats {
  return {
    machine_count: data?.machine_aggregate?.aggregate?.count || 0,
    patient_count: data?.patient_aggregate?.aggregate?.count || 0,
    center_count: data?.center_aggregate?.aggregate?.count || 0,
  }
}
