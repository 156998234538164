import { useAuthStore } from "@/store/auth";
import { RouteRecordRaw } from "vue-router";

export default [
  // PROFILES
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: { title: "profile.title", hideDrawer: false, selectedNavbarEntry: "profile" },
  },
  // VERSIONS
  {
    path: "/versions",
    name: "Versions",
    component: () => import("@/views/Versions.vue"),
    meta: { title: "versions.title", hideDrawer: false, selectedNavbarEntry: "version" },
  },
  {
    path: "/version",
    name: "CreateVersion",
    component: () => import("@/views/Version.vue"),
    meta: { title: "version.title.add", hideDrawer: false, selectedNavbarEntry: "version" },
  },
  {
    path: "/version/:id/edit",
    name: "EditVersion",
    component: () => import("@/views/Version.vue"),
    meta: { title: "version.title.edit", hideDrawer: false, selectedNavbarEntry: "version" },
  },
  {
    path: "/version/:id/view",
    name: "ViewVersion",
    component: () => import("@/views/ViewVersion.vue"),
    meta: { title: "version.title.view", hideDrawer: false, selectedNavbarEntry: "version" },
  },
  // Machines
  {
    path: "/machines",
    name: "Sessions",
    component: () => import("@/views/Machines.vue"),
    meta: { title: "machines.title", hideDrawer: false, selectedNavbarEntry: "machine" },
  },
  {
    path: "/machine",
    name: "CreateMachine",
    component: () => import("@/views/Machine.vue"),
    meta: { title: "machine.title.add", hideDrawer: false, selectedNavbarEntry: "machine" },
  },
  {
    path: "/machine/:id/edit",
    name: "EditMachine",
    component: () => import("@/views/Machine.vue"),
    meta: { title: "machine.title.edit", hideDrawer: false, selectedNavbarEntry: "machine" },
  },
  {
    path: "/machine/:id/view",
    name: "ViewMachine",
    component: () => import("@/views/ViewMachine.vue"),
    meta: { title: "machine.title.view", hideDrawer: false, selectedNavbarEntry: "machine" },
  },
  {
    path: "/machines/:machineId/session/:sessionId/view",
    name: "ViewMachineSession",
    component: () => import("@/views/ViewPatientSession.vue"),
    meta: { title: "patient.title.session", hideDrawer: false, selectedNavbarEntry: "session", from: "machine" },
  },
  // PATIENTS
  {
    path: "/patients",
    name: "Patients",
    component: () => import("@/views/Patients.vue"),
    meta: { title: "patients.title", hideDrawer: false, selectedNavbarEntry: "patient" },
  },
  {
    path: "/patient",
    name: "CreatePatient",
    component: () => import("@/views/Patient.vue"),
    meta: { title: "patient.title.add", hideDrawer: false, selectedNavbarEntry: "patient" },
  },
  {
    path: "/patient/:id/edit",
    name: "EditPatient",
    component: () => import("@/views/Patient.vue"),
    meta: { title: "patient.title.edit", hideDrawer: false, selectedNavbarEntry: "patient" },
  },
  {
    path: "/patient/:id/view",
    name: "ViewPatient",
    component: () => import("@/views/ViewPatient.vue"),
    meta: { title: "patient.title.view", hideDrawer: false, selectedNavbarEntry: "patient" },
  },
  {
    path: "/patient/:patientId/session/:sessionId/view",
    name: "ViewPatientSession",
    component: () => import("@/views/ViewPatientSession.vue"),
    meta: { title: "patient.title.session", hideDrawer: false, selectedNavbarEntry: "patient", from: "patient" },
  },
  // CENTERS
  {
    path: "/centers",
    name: "Centers",
    component: () => import("@/views/Centers.vue"),
    meta: { title: "centers.title", hideDrawer: false, selectedNavbarEntry: "center" },
  },
  {
    path: "/center",
    name: "CreateCenter",
    component: () => import("@/views/Center.vue"),
    meta: { title: "center.title.add", hideDrawer: false, selectedNavbarEntry: "center" },
  },
  {
    path: "/center/:id/edit",
    name: "EditCenter",
    component: () => import("@/views/Center.vue"),
    meta: { title: "center.title.edit", hideDrawer: false, selectedNavbarEntry: "center" },
  },
  {
    path: "/center/:id/view",
    name: "ViewCenter",
    component: () => import("@/views/ViewCenter.vue"),
    meta: { title: "center.title.view", hideDrawer: false, selectedNavbarEntry: "center" },
  },
  // DISTRIBUTORS
  {
    path: "/distributors",
    name: "Distributors",
    component: () => import("@/views/Distributors.vue"),
    meta: { title: "distributors.title", hideDrawer: false, selectedNavbarEntry: "distributor" },
  },
  {
    path: "/distributor",
    name: "CreateDistributor",
    component: () => import("@/views/Distributor.vue"),
    meta: { title: "distributor.title.add", hideDrawer: false, selectedNavbarEntry: "distributor" },
  },
  {
    path: "/distributor/:id/edit",
    name: "EditDistributor",
    component: () => import("@/views/Distributor.vue"),
    meta: { title: "distributor.title.edit", hideDrawer: false, selectedNavbarEntry: "distributor" },
  },
  {
    path: "/distributor/:id/view",
    name: "ViewDistributor",
    component: () => import("@/views/ViewDistributor.vue"),
    meta: { title: "distributor.title.view", hideDrawer: false, selectedNavbarEntry: "distributor" },
  },
  // OPERATORS
  {
    path: "/operators",
    name: "Operators",
    component: () => import("@/views/Operators.vue"),
    meta: { title: "operators.title", hideDrawer: false, selectedNavbarEntry: "operator" },
  },
  {
    path: "/operator/:id/edit",
    name: "EditOperator",
    component: () => import("@/views/Operator.vue"),
    meta: { title: "operator.title.edit", hideDrawer: false, selectedNavbarEntry: "operator" },
  },
  {
    path: "/operator",
    name: "CreateOperator",
    component: () => import("@/views/Operator.vue"),
    meta: { title: "operator.title.add", hideDrawer: false, selectedNavbarEntry: "operator" },
  },
  {
    path: "/operator/:id/view",
    name: "ViewOperator",
    component: () => import("@/views/ViewOperator.vue"),
    meta: { title: "operator.title.view", hideDrawer: false, selectedNavbarEntry: "operator" },
  },
  // TECHNICIANS
  {
    path: "/technicians",
    name: "Technicians",
    component: () => import("@/views/Technicians.vue"),
    meta: { title: "technicians.title", hideDrawer: false, selectedNavbarEntry: "technician" },
  },
  {
    path: "/technician/:id/edit",
    name: "EditTechnician",
    component: () => import("@/views/Technician.vue"),
    meta: { title: "technician.title.edit", hideDrawer: false, selectedNavbarEntry: "technician" },
  },
  {
    path: "/technician/:id/view",
    name: "ViewTechnician",
    component: () => import("@/views/ViewTechnician.vue"),
    meta: { title: "technician.title.view", hideDrawer: false, selectedNavbarEntry: "technician" },
  },
  {
    path: "/technician",
    name: "CreateTechnician",
    component: () => import("@/views/Technician.vue"),
    meta: { title: "technician.title.add", hideDrawer: false, selectedNavbarEntry: "technician" },
  },
  {
    path: "/patient-profile",
    name: "PatientProfile",
    component: () => import("@/views/PatientProfile.vue"),
    meta: { title: "patient.title.view", hideDrawer: false, selectedNavbarEntry: "patient-profile" },
  },
  {
    path: "/patient-profile/session/:sessionId/view",
    name: "PatientProfileSessions",
    component: () => import("@/views/ViewPatientSession.vue"),
    meta: { title: "patient.title.session", hideDrawer: false, selectedNavbarEntry: "patient-profile", from: "profile" },
  },
  {
    path: "/center-profile",
    name: "CenterProfile",
    component: () => import("@/views/CenterProfile.vue"),
    meta: { title: "center.title.view", hideDrawer: false, selectedNavbarEntry: "center-profile" },
  },
  {
    path: "/distributor-profile",
    name: "DistributorProfile",
    component: () => import("@/views/DistributorProfile.vue"),
    meta: { title: "distributor.title.view", hideDrawer: false, selectedNavbarEntry: "distributor-profile" },
  },
  // DASHBOARD
  {
    path: "/",
    name: "Home",
    redirect: () => {
      const auth = useAuthStore();
      const dashboard = auth.getDashboard()

      switch (dashboard) {
        case "distributors":
          return { path: '/distributors' }
        case "patient":
          return { path: `/patient-profile` }
        case "operators":
          return { path: '/operators' }
        case "centers":
          return { path: '/centers' }
        case "patients":
          return { path: '/patients' }
        case "machines":
          return { path: '/machines' }
        default:
          return { path: '/sign-in' }
      }
    },
  },
  // CHANGE PASSWORD
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("@/views/ChangePassword.vue"),
    meta: { title: "change-password.title", hideDrawer: true,  },
  },
  // SIGN UP
  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import("@/views/SignUp.vue"),
    meta: { title: "sign-up.title", hideDrawer: true,  },
  },
] as RouteRecordRaw[];

