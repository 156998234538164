import { Comment } from './models'

interface RawComment {
  id: string
  content: string
  date: string
} 

export function mapComments(data?: string | null): Comment[] {
  if (!data) {
    return []
  }

  try {
    const parsedData: RawComment[] = JSON.parse(data)
    return parsedData.map(mapComment)
  } catch (error) {
    return [
      {
        id: '0',
        content: data,
        date: new Date()
      }
    ]
  }
}

function mapComment(data: RawComment | null): Comment {
  if (!data) throw new Error('Comment data is null')

  const { id, content, date } = data

  return {
    id: id,
    content: content,
    date: new Date(date)
  }
}