import { graphql } from "@/gql"

export const UPDATE_CENTER = graphql(`
mutation UpdateCenter(
  $id: uuid!
  $name: String!
  $comments: String!
  $country: String!
  $address: String!

  $usersToAdd: [users_insert_input!]!
  $usersToUpdate: [users_updates!]!
  $accountsToAdd: [account_insert_input!]!
  $accountsToUpdate: [account_updates!]!
  $accountsToDelete: [uuid!]!
  
  $distributorId: uuid!
  $primaryAccountId: uuid!
) {
  # INSERT users and accounts
  insertUsers(
    objects: $usersToAdd
  ) {
    affected_rows
  }

  insert_account(
    objects: $accountsToAdd
  ) {
    affected_rows
  }

  # UPDATE PRIMARY ACCOUNT
  update_primary_account: update_center_by_pk(
    pk_columns: {
      id: $id
    }
    _set: {
      primary_account_id: $primaryAccountId
    }
  ) {
    id
  }

  # UPDATE users and accounts
  update_account_many(
    updates: $accountsToUpdate
  ) {
    affected_rows
  }

  update_users_many(
    updates: $usersToUpdate
  ) {
    affected_rows
  }

  # DELETE users and accounts

  delete_account(where: { _and: [
    { user_id: {_in: $accountsToDelete}}
    { center_id: {_eq: $id}}
  ]}) {
    affected_rows
  }

  # FINALLY UPDATE CENTER
  update_center_by_pk(
    pk_columns: {
      id: $id
    }
    _set: {
      name: $name
      comments: $comments
      country: $country
      address: $address
      distributor_id: $distributorId
    }
  ) {
    ...Center
  }
}
`)

export const QUERY_CENTERS_LIST = graphql(`
query QueryCentersList {
  center {
    id
    name
    identifier
  }
}
`)

export const QUERY_CENTER_LIST_FOR_ID = graphql(`
query QueryCenterListForId($id: uuid!) {
  center(where: {distributor_id: {_eq: $id}}) {
    id
    name
    identifier
  }
}
`)

export const QUERY_CENTER_INFOS = graphql(`
query QueryCenterInfos(
  $id: uuid!
  $operator_where: operator_bool_exp!
  $operator_order_by: [operator_order_by!]!
  $operator_limit: Int!
  $operator_offset: Int!
  $patient_where: patient_bool_exp!
  $patient_order_by: [patient_order_by!]!
  $patient_limit: Int!
  $patient_offset: Int!
) {
  center_by_pk(id: $id) {
    ...Center
    operators(
      where: $operator_where,
      order_by: $operator_order_by,
      offset: $operator_offset,
      limit: $operator_limit
    ) {
      ...Operator
    }
    operators_aggregate(where: $operator_where) {
      aggregate {
        count
      }
    }
    patients(
      where: $patient_where,
      order_by: $patient_order_by,
      offset: $patient_offset,
      limit: $patient_limit
    ) {
      ...Patient
    }
    patients_aggregate(where: $patient_where) {
      aggregate {
        count
      }
    }
  }
}
`)

export const DELETE_CENTER = graphql(`
mutation DeleteCenter($id: uuid!) {
  delete_account(where: {center_id: {_eq: $id}}) {
    affected_rows
  }

  delete_center_by_pk(id: $id) {
    id
  }

  updateUsers(where: {account: { center_id: {_eq: $id}}}, _set: { disabled: true }) {
    affected_rows
  }
}
`)

export const SUBSCRIBE_CENTERS = graphql(`
subscription SubscribeCenters(
  $order_by: [center_order_by!]!
  $limit: Int!
  $offset: Int!
  $where: center_bool_exp!
) {
  center(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
    ...Center
  }
}
`)

export const QUERY_ALL_CENTERS = graphql(`
query QueryAllCenters($where: center_bool_exp!) {
  center(where: $where) {
    ...Center
  }
}
`)

export const SUBSCRIBE_CENTERS_COUNT = graphql(`
subscription SubscribeCentersCount($where: center_bool_exp! = {}) {
  center_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
`)

export const QUERY_CENTER = graphql(`
query QueryCenter($id: uuid!) {
  center_by_pk(id: $id) {
    ...Center
  }
}
`)

export const ADD_CENTER = graphql(`
mutation AddCenter(
  $id: uuid!
  $name: String!
  $comments: String!
  $country: String
  $address: String!
  $primaryAccountId: uuid!
  $distributorId: uuid
  $users: [users_insert_input!]!
  $accounts: [account_insert_input!]!
) {
  insertUsers(
    objects: $users
  ) {
    affected_rows
  }

  insert_center_one(object: {
    id: $id
    name: $name
    comments: $comments
    country: $country
    address: $address
    distributor_id: $distributorId
  }) {
    ...Center
  }

  insert_account(
    objects: $accounts
  ) {
    affected_rows
  }

  update_center_by_pk(
    _set: {
      primary_account_id: $primaryAccountId
    }
    pk_columns: {
      id: $id
    }
  ) {
    ...Center
  }
}
`)

export const UPDATE_COMMENTS = graphql(`
  mutation UpdateCenterComments($id: uuid!, $comments: String!) {
  update_center_by_pk(pk_columns: { id: $id }, _set: { comments: $comments }) {
    id
  }
}
`)
