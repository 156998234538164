import { Center_Bool_Exp, Center_Order_By, Order_By } from "@/gql/graphql";
import { ref, computed } from "vue";

export function useCentersFilters() {
  const columnOrders = ref<Center_Order_By>({ id: Order_By.Desc })
  const textFilter = ref("");

  const filters = computed<Center_Bool_Exp>(() => 
    textFilter.value === "" ? {} :
      {
        _or: [
          { identifier: { _ilike: `%${textFilter.value}%` } },
          { name: { _ilike: `%${textFilter.value}%` } },
          {
            primary_account: {
              firstname: { _ilike: `%${textFilter.value}%` },
            }
          },
          {
            primary_account: {
              lastname: { _ilike: `%${textFilter.value}%` },
            }
          },
          {
            primary_account: {
              user: { email: { _ilike: `%${textFilter.value}%` } },
            }
          },
          {
            primary_account: {
              user: { phoneNumber: { _ilike: `%${textFilter.value}%` } },
            }
          }
        ],
      }
  );

  return {
    textFilter,
    columnOrders,
    filters
  }
}
