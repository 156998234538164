<template>
  <app-top-bar>
    <app-date-picker class="mr-3" :title="$t('common.date-filter')" :model-value="dateSearched"
      @update:model-value="$emit('update:dateSearched', $event)" />
    <export-btn @click="exportData" />
    <div class="flex-grow-1"></div>
    <v-search-filter :placeholder="$t('machine.actions.search')" :model-value="textSearched"
      @update:model-value="$emit('update:textSearched', $event)" />
  </app-top-bar>
  <app-table :headers="headers" :loading="loading" :limit="limit" :total="total" :current-page="page"
    @update:currentPage="$emit('update:page', $event)" :items="sessions" @update:filters="$emit('update:order', $event)"
    :defaultSelected="{ key: 'name', order: Order_By.Asc }">
    <tr v-for="session of sessions" :key="session.id">
      <td>{{ d(session.date, 'short') }}</td>
      <td>{{ formatHour(session.date) }}</td>

      <td v-if="firstColumn == 'patient'">{{ session.patient_identifier }}</td>
      <td v-else>{{ session.dyalizer_identifier }}</td>

      <td>{{ session.identifier }}</td>
      <td>{{ session.duration }}</td>
      <td>{{ session.blocking_alarms }}</td>
      <td>{{ session.suspensive_alarms_amount }}</td>
      <td class="d-flex justify-space-between align-session">
        <v-btn-icon icon="mdi-arrow-right" class="text-caption" :to="itemLink(session.id)" v-if="session.patient_identifier" />
      </td>
    </tr>
  </app-table>
</template>
<script setup lang="ts">
import { useI18n } from '@/plugins/i18n';
import { computed } from 'vue';
import { formatHour } from '@/utils/date';
import { Session } from '@/services/sessions/models';
import { Order_By } from '@/gql/graphql';
import { useActionReporting } from '@/services/action_reporting';
import { exportTo } from '@/utils/export';
import { AnyOrder } from '@/utils/order';

const { logExport } = useActionReporting()
const { t, d } = useI18n()

const props = defineProps<{
  firstColumn: 'machine' | 'patient';
  dateSearched: string[];
  textSearched: string;
  page: number;
  sessions: Session[];
  loading: boolean;
  order: AnyOrder;
  limit: number;
  total: number | undefined;
  itemLink: (sessionId: string) => string;
  getAllSessions: () => Promise<Session[]>;
}>();

defineEmits<{
  'update:order': [AnyOrder];
  'update:textSearched': [string];
  'update:dateSearched': [string[]];
  'update:page': [number];
}>();

const headers = computed(() => [
  {
    title: t("sessions.headers.start_date"),
    key: "date_session",
    filter: "date_session",
  },
  {
    title: t("sessions.headers.start_hour"),
    key: "date_session",
    filter: "date_session",
  },
  ...(props.firstColumn === 'patient' ? [
    {
      title: t("sessions.headers.patient_id"),
      key: "patient_id",
      filter: "patient.identifier",
    },
  ] : [
    {
      title: t("sessions.headers.machine_id"),
      key: "machine_id",
      filter: "machine.identifier",
    },
  ]),
  {
    title: t("sessions.headers.session_number"),
    key: "session_number",
    filter: "identifier",
  },
  {
    title: t("sessions.headers.duration"),
    key: "duration",
  },
  {
    title: t("sessions.headers.blocking_alarms"),
    key: "blocking_alarms",
  },
  {
    title: t("sessions.headers.suspensive_alarms"),
    key: "suspensive_alarms",
  },
  {
    title: '',
    key: "actions",
  }
])

async function exportData(type: 'csv' | "pdf") {
  const sessions = await props.getAllSessions();

  const columns = headers.value.map(e => e.title);

  const data = sessions.map(e => [
    d(e.date, 'short'),
    formatHour(e.date),
    props.firstColumn === 'machine' ? e.dyalizer_identifier : e.patient_identifier,
    e.identifier,
    e.duration,
    e.blocking_alarms,
    e.suspensive_alarms_amount,
  ])

  await logExport("session")
  exportTo("sessions", type, data, columns)
}
</script>