<template>
  <v-table hover class="bg-background-700">
    <template #wrapper>
      <table>
        <thead>
          <tr>
            <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.firstname') }}</th>
            <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.lastname') }}</th>
            <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.phone-number') }}</th>
            <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.email') }}</th>
            <th class="text-left font-weight-bold w-0">{{ $t('accounts-table.headers.status') }}</th>
            <th class="text-left font-weight-bold w-0 " colspan="3">
              {{ $t('accounts-table.headers.main-contact') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="onlyOne != true">
            <td colspan="8" class="text-center">
              <v-btn rounded="lg" variant="outlined" class="bg-background-500 w-100 my-4 text-none dashed"
                prepend-icon="mdi-plus" @click="$emit('create:user')" :class="isDark ? 'dark' : 'light'">
                {{ $t('accounts-table.add-account-button') }}
              </v-btn>
            </td>
          </tr>
          <tr v-for="(user, index) in modelValue" :key="user.id">
            <AccountsTableRow v-model="modelValue[index]" :account-errors="accountsErrors[index]"
              @delete="$emit('delete:user', user.id)" :is-primary="primaryAccount === user.id"
              @update:is-primary="$emit('update:primaryAccount', user.id)" />
          </tr>
        </tbody>
      </table>
    </template>
  </v-table>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useTheme } from 'vuetify';
import { UserForm, UserError } from '@/store/user/models';
import AccountsTableRow from '@/components/molecules/AccountsTableRow.vue';

const theme = useTheme()
const isDark = computed(() => theme.global.current.value.dark)

defineProps<{
  onlyOne?: boolean | undefined
  modelValue: UserForm[]
  primaryAccount: string | null
  accountsErrors: UserError[]
}>()

defineEmits<{
  'update:primaryAccount': [string]
  'delete:user': [string]
  'create:user': []
}>()
</script>
<style scoped lang="scss">
.dashed {
  border-width: 2px;
  border-style: dashed;

  &.dark {
    border-color: rgb(var(--v-theme-background-100));
  }

  &.light {
    border-color: rgb(var(--v-theme-background-800));
  }
}
</style>
