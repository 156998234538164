import { debouncedRef } from "@vueuse/core";
import { Ref, computed, ref, watch } from "vue";

interface Pagination {
  limit: Ref<number>;
  page: Ref<number>;
  offset: Ref<number>;
}

export function usePagination(l: number = 15): Pagination {
  const limit = ref(l);
  const page = ref(1);
  const offset = computed(() => (page.value - 1) * limit.value);
  return {
    limit,
    page,
    offset,
  };
}

export const usePaginatedQueryArgs = <WhereT, OrderT>(filters: Ref<WhereT>, columnOrders: Ref<OrderT>, pagination: Pagination) => {
  const { limit, offset } = pagination;

  return debouncedRef(
    computed(() => ({
      offset: offset.value,
      limit: limit.value,
      where: filters.value,
      order_by: columnOrders?.value
    })),
    400
  )
}

export const useQueryArgs = <WhereT>(filters: Ref<WhereT>) => {
  return debouncedRef(
    computed(() => ({
      where: filters.value,
    })),
    400
  )
}

export const useDateFilter = (dateSelected: Ref<string[]>) => {
  const rawDateFilter = ref<string[]>([]);
  const dateFilter = computed(() => {
    if (rawDateFilter.value.length === 0) return [];
    if (rawDateFilter.value.length === 1) return rawDateFilter.value;
  
    return [
      rawDateFilter.value[0],
      rawDateFilter.value[rawDateFilter.value.length - 1]
    ]
  });
  
  watch(dateFilter, (newVal) => {
    dateSelected.value = newVal;
  });

  return {
    dateFilter,
  }
}