<template>
  <v-table hover class="bg-background-700">
    <thead>
      <tr>
        <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.firstname') }}</th>
        <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.lastname') }}</th>
        <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.phone-number') }}</th>
        <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.email') }}</th>
        <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.status') }}</th>
        <th class="text-left font-weight-bold">{{ $t('accounts-table.headers.main-contact') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in modelValue" :key="user.id">
        <td class="pr-2">
          <span>{{ user.firstname }}</span>
        </td>
        <td class="pr-2">
          <span>{{ user.lastname }}</span>
        </td>
        <td class="pr-2">
          <span>{{ user.phone }}</span>
        </td>
        <td class="pr-2">
          <span>{{ user.email }}</span>
        </td>
        <td class="pr-2">
          <span v-if="!user.disabled" class="text-tag-1"> {{ t('distributor.status-active') }} </span>
          <span v-else class="text-typography-700"> {{ t('distributor.status-inactive') }} </span>
        </td>
        <td class="pr-2">
          <v-icon v-if="user.id === primaryAccount" color="primary">mdi-check</v-icon>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>
<script setup lang="ts">
import { useI18n } from '@/plugins/i18n';
import { UserForm } from '@/store/user/models';

const { t } = useI18n()

defineProps<{
  modelValue: UserForm[]
  primaryAccount: string
}>()

const emit = defineEmits<{
  'update:modelValue': [UserForm[]]
  'update:primaryAccount': [string]
}>()
</script>
