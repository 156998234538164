<template>
  <v-app>
    <v-main class="bg-background-900">
      <AppDrawer v-if="!hideDrawer" />
      <router-view :key="$route.path" v-slot="{ Component }">
        <v-slide-y-transition mode="out-in">
          <Suspense>
            <template #default>
              <div class="pa-8 content d-flex flex-column">
                <component :is="Component" />
              </div>
            </template>
            <template #fallback>
              <div class="d-flex flex-row justify-space-around align-center align-start w-100 h-50">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </Suspense>
        </v-slide-y-transition>
      </router-view>
    </v-main>
    <Toastmanager />
    <v-overlay v-model="overlayStore.enable" scrim="background-500" @click:outside="() => overlayStore.overlayClick()"
      :z-index="1005"></v-overlay>
  </v-app>
</template>

<script setup lang="ts">
import { computed, onErrorCaptured, ref } from 'vue';
import { useRoute } from 'vue-router';
import AppDrawer from '@/components/templates/AppDrawer.vue';
import Toastmanager from "@/components/molecules/ToastManager.vue";
import { useOverlayStore } from './store/overlay';
import * as Sentry from "@sentry/vue";

const overlayStore = useOverlayStore();

const route = useRoute();
const error = ref<null | string>(null);

const hideDrawer = computed(() => {
  if (!route || !route.meta || route.meta['hideDrawer'] == undefined) return true;

  return route.meta.hideDrawer as boolean;
});

onErrorCaptured((e) => {
  Sentry.captureException(e)
  console.error('An error occurred:', e)

  if (e instanceof Error) {
    console.error(e.message)
    error.value = e.message
  } else {
    console.error(e)
    error.value = 'An error occurred'
  }

  return false
})
</script>

<style scoped>
main {
  width: 100%;
  height: available;
}

.content {
  height: 100vh;
}
</style>
