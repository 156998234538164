import { useI18n } from "@/plugins/i18n"
import { ApolloError } from "@apollo/client/errors"
import * as Sentry from "@sentry/vue"

export function handleErrors(error: ApolloError) {
    console.error(error.name, error.message, error.graphQLErrors.map(e => e.message));
    const hasError = (msg: string) => error.message?.includes(msg) || error.graphQLErrors.some(e => (e.extensions?.path as string)?.includes(msg))
    
    const { t } = useI18n()

    if (hasError("users_phone_number_key")) {
        return t('patient.errors.phone-error')
    }

    if (hasError("patients_email_key")) {
        return t('patient.errors.email-error')
    }

    if (hasError("distributor_id")) {
        return t('distributor.errors.missing-distributor-id')
    }

    if (hasError("operator_center_id_fkey")) {
        return t('center.errors.operator-present')
    }

    if (hasError("users_email_key")) {
        return t('center.errors.email-error')
    }

    if (hasError("patient_machine_id_key")) {
        return t('machine.errors.machine-present')
    }

    if (hasError("patient_identifier_key")) {
        return t('patient.errors.identifier-present')
    }

    error.graphQLErrors?.forEach((error) => {
      Sentry.captureMessage(error.message, {
          level: "error",
          fingerprint: ["{{ default }}", "{{ transaction }}"],
          contexts: {
              apolloGraphQLError: {
                  error,
                  message: error.message,
                  extensions: error.extensions,
              },
          },
      });
    });

    return t('common.internal-error')
}
