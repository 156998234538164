import { h, watch } from 'vue'
import { ThemeDefinition, createVuetify } from 'vuetify'
import { useStorage } from '@vueuse/core'

import { VProgressCircular, VOverlay, VDatePicker, VProgressLinear, VVirtualScroll, VOtpInput, VSwitch, VTab, VTabs, VTooltip, VPagination, VTable, VMenu, VList, VSlideYTransition, VAvatar, VHover, VIcon, VImg, VDivider, VNavigationDrawer, VCard, VCardText, VDialog, VCheckbox, VApp, VMain, VTextField, VCol, VRow, VContainer, VForm, VBtn, VFileInput, VLabel, VSelect, VTextarea, VAutocomplete, VChip, VClassIcon } from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { fr, en } from 'vuetify/locale'

import "@/index.scss"
import '@mdi/font/css/materialdesignicons.css'
import { useI18n } from './i18n'

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    // Background colors
    "background-100": "#BEBEBE",
    "background-300": "#3A3A3A",
    "background-500": "#2C2C2C",
    "background-700": "#232323",
    "background-900": "#1A1A1A",

    // Typography colors
    "typography-900": "#FFFFFF",
    "typography-700": "#8F8F8F",
    "typography-500": "#1A1A1A",

    // Main colors
    "primary-500": "#CCE5FF",
    "primary-900": "#4F9EF2",

    // Accent colors
    "tag-1": "#4DEE5D",
    "suppression-900": "#EB402D",
    "suppression-500": "#591F1F",
  },
};

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    // Background colors
    "background-100": "#FFFFFF",
    "background-300": "#D3D3D3",
    "background-500": "#EEEEEE",
    "background-700": "#FEFEFE",
    "background-900": "#FAFAFA",

    // Typography colors
    "typography-900": "#1A1A1A",
    "typography-700": "#8F8F8F",
    "typography-500": "#FFFFFF",

    // Main colors
    "primary-500": "#CCE5FF",
    "primary-900": "#00A4E3",

    // Accent colors
    "tag-1": "#00AF12",
    "suppression-900": "#EB402D",
    "suppression-500": "#591F1F",
  },
}

const { locale } = useI18n();
const theme = useStorage("theme", "lightTheme")

// Declare alias typings for global components
declare module 'vue' {
  export interface GlobalComponents {
    VBtnPrimary: VBtn
    VBtnCancel: VBtn
    VBtnBackgroundLink: VBtn
    VBtnIcon: VBtn
    VSearchFilter: VTextField
    VBtnTopBar: VBtn
    VBtnDanger: VBtn
    VBtnDangerBig: VBtn
  }
}

export const vuetify = createVuetify({
  components: {
    VProgressLinear,
    VVirtualScroll,
    VTimePicker,
    VOtpInput,
    VDatePicker,
    VSwitch,
    VTab,
    VTabs,
    VTooltip,
    VCheckbox,
    VMain,
    VApp,
    VSlideYTransition,
    VTextField,
    VCol,
    VRow,
    VContainer,
    VForm,
    VBtn,
    VFileInput,
    VLabel,
    VSelect,
    VTextarea,
    VAutocomplete,
    VChip,
    VDialog,
    VCard,
    VCardText,
    VNavigationDrawer,
    VImg,
    VDivider,
    VIcon,
    VAvatar,
    VHover,
    VProgressCircular,
    VPagination,
    VTable,
    VMenu,
    VList,
    VOverlay,
  },
  aliases: {
    "VBtnPrimary": VBtn,
    "VBtnCancel": VBtn,
    "VBtnBackgroundLink": VBtn,
    "VBtnIcon": VBtn,
    "VSearchFilter": VTextField,
    "VBtnTopBar": VBtn,
    "VBtnDanger": VBtn,
    "VBtnDangerBig": VBtn,
  },
  defaults: {
    VPagination: {
      density: "compact",
      prevIcon: "mdi-arrow-left",
      nextIcon: "mdi-arrow-right",
    },
    VCheckbox: {
      color: "primary-900",
    },
    VBtnDangerBig: {
      color: "suppression-900",
      density: "default",
      variant: "flat",
      rounded: 'md',
      class: "text-none bg-background-500 pt-4 pb-8",
    },
    VBtnTopBar: {
      variant: "outlined",
      rounded: "lg",
      class: "text-none bg-background-500",
    },
    VSearchFilter: {
      variant: "outlined",
      density: "compact",
      hideDetails: true,
      bgColor: "background-500",
      prependInnerIcon: "mdi-magnify",
      rounded: "lg",
    },
    VBtnPrimary: {
      color: "primary-900",
      density: "default",
      variant: "flat",
      rounded: true,
      class: "text-none"
    },
    VBtnCancel: {
      density: "default",
      variant: "flat",
      rounded: true,
      class: "text-none"
    },
    VBtnDanger: {
      color: "suppression-900",
      density: "default",
      variant: "flat",
      rounded: true,
      class: "text-none bg-background-500",
    },
    VBtnIcon: {
      variant: "plain"
    },
    VBtnBackgroundLink: {
      variant: "plain",
      class: "text-none",
    },
    VSelect: {
      variant: "outlined",
      density: "compact",
    },
    VTextField: {
      variant: "outlined",
      density: "compact",
      bgColor: "background-700",
      rounded: "lg",
      class: "blue-outline",
    },
    VTabs: {
      color: "primary-900",
    },
    VTab: {
      class: "text-none text-typography-700",
    }
  },
  directives,
  theme: {
    defaultTheme: theme.value,
    themes: {
      darkTheme: darkTheme,
      lightTheme: lightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      lucide: {
        component: (props?: any) => h(VClassIcon, { ...props, icon: `icon-${props.icon}` }),
      },
    },
  },
  locale: {
    locale: locale.value,
    fallback: 'en',
    messages: {
      fr,
      en,
    },
  },
})


// Update vuetify locale
watch(locale, (val) => {
  vuetify.locale.current.value = val
})

