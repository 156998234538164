<template>
  <v-hover v-slot="{ isHovering, props }">
    <div :class="generateClasses($props.class ?? '', isHovering || false, selected)" @click="$emit('click')"
      v-bind="props">
      <div class="mr-4 ml-2 d-flex align-center justify-center">
        <slot></slot>
      </div>
      <div>
        {{ text }}
      </div>
    </div>
  </v-hover>
</template>
<script setup lang="ts">
defineSlots<{
  default: SVGAElement;
}>();
defineProps<{
  text: string;
  selected: boolean;
  class?: string;
}>();

defineEmits<{
  click: [];
}>();

function generateClasses(classes: string, hover: boolean, selected: boolean) {
  const base = `${classes} d-flex flex-row align-center pointer pa-3 rounded-lg`;

  if (selected) {
    return base + " bg-primary-900";
  }

  if (hover) {
    return base + " bg-background-500"
  }

  return base;
}
</script>
<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
