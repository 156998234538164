import { useQuery, useMutation, useSubscription } from "@vue/apollo-composable"
import { debouncedRef } from "@vueuse/core"
import { useI18n } from "@/plugins/i18n"
import { computed, ref } from "vue"

import { CenterFragment, DistributorFragment, DistributorFragmentDoc, Distributor_Bool_Exp, Distributor_Order_By, Order_By } from "@/gql/graphql"

import { Distributor, DistributorForm } from "./models"
import { UPDATE_DISTRIBUTOR, QUERY_DISTRIBUTOR_INFOS, QUERY_ALL_DISTRIBUTORS, QUERY_DISTRIBUTOR, UPDATE_COMMENTS, ADD_DISTRIBUTOR, SUBSCRIBE_DISTRIBUTORS, SUBSCRIBE_DISTRIBUTOR_COUNT, QUERY_DISTRIBUTORS_LIST } from "./queries"
import { mapDistributors, mapDistributor, mapDistributorStats } from "./mappers"
import { useDistributorsFilters } from "./filters"

import { usePaginatedQueryArgs, usePagination, useQueryArgs } from "@/utils/filters"
import { Comment } from "@/services/comments/models"
import { useCentersFilters } from "@/services/centers/filters"
import { mapCenters } from "@/services/centers/mappers"
import { useFragment } from "@/gql"
import { handleErrors } from "@/utils/errors"
import { SUBSCRIBE_CENTERS_COUNT } from "@/services/centers/queries"
import { SUBSCRIBE_PATIENTS_COUNT } from "@/services/patients/queries"

export function useDistributors() {
  let distributorRefetchHandlers = [] as any[]
  let distributorRefetchCallback = () => {
    distributorRefetchHandlers.forEach((refetch) => refetch())
  }

  const { textSearch, zonesSelected, statusesSelected, filters, columnOrders } = useDistributorsFilters()

  function queryDistributorInfos(id: string) {
    const centerColumnOrders = ref<Distributor_Order_By>({ identifier: Order_By.Desc })
    const { textFilter: centerTextFilter, filters: centerFilters } = useCentersFilters()
    const centersPagination = usePagination()

    const args = debouncedRef(
      computed(() => ({
        id: id,
        offset: centersPagination.offset.value,
        limit: centersPagination.limit.value,
        where: centerFilters.value,
        order_by: centerColumnOrders.value,
      })),
      400
    )

    const { result, loading, refetch } = useQuery(QUERY_DISTRIBUTOR_INFOS, args)

    distributorRefetchHandlers.push(refetch)

    const distributor = computed(() => {
      if (loading.value) return null
      const d = useFragment(DistributorFragmentDoc, result.value?.distributor_by_pk)
      if (!d) throw new Error("Distributor not found")
      return mapDistributor(d)
    })

    const stats = computed(() => mapDistributorStats(result?.value || null))

    const centers = computed(() => {
      if (loading.value) return []

      return mapCenters(
        result.value?.distributor_by_pk?.centers as CenterFragment[]
      )
    })

    const centerTotal = computed(() => result.value?.distributor_by_pk?.centers_aggregate?.aggregate?.count)

    return {
      loading,
      centersPagination,
      centerTextFilter,
      centerColumnOrders,
      centerTotal,
      distributor,
      centers,
      stats,
    };
  }

  function queryDistributor(id: string) {
    const { result, loading, refetch } = useQuery(QUERY_DISTRIBUTOR, {
      id,
    })

    distributorRefetchHandlers.push(refetch)

    return computed<Distributor | null>(() => {
      if (loading.value) return null
      return mapDistributor(result.value?.distributor_by_pk as DistributorFragment)
    })
  }

  function queryDistributorList() {
    const { result, loading } = useQuery(QUERY_DISTRIBUTORS_LIST)

    return computed<{ title: string, value: string }[]>(() => {
      if (loading.value) return []
      return result.value?.distributor.map((distributor) => ({
        title: `${distributor.company_name ?? '-'} (${distributor.identifier})`,
        value: distributor.id
      })) ?? []
    })
  }

  function queryAllDistributors(): Promise<Distributor[]> {
    const args = useQueryArgs<Distributor_Bool_Exp>(filters)
    const { onResult, onError } = useQuery(QUERY_ALL_DISTRIBUTORS, args)

    return new Promise((resolve, reject) => {
      onResult((result) => {
        if (result.loading) return

        const distributorFragments = result.data?.distributor.map(
          (distributor) => useFragment(DistributorFragmentDoc, distributor)
        )
        const maybeDistributors = mapDistributors(distributorFragments)
        resolve(maybeDistributors)
      })

      onError(() => reject())
    })
  }

  function useStatus() {
    const { t } = useI18n()

    const statues = [
      { title: t('distributor.status-active'), value: true },
      { title: t('distributor.status-inactive'), value: false },
    ]
    return {
      statues,
    }
  }

  function queryDistributors() {
    const pagination = usePagination()
    const args = usePaginatedQueryArgs<Distributor_Bool_Exp, Distributor_Order_By>(filters, columnOrders, pagination)
    const countWhere = useQueryArgs<Distributor_Bool_Exp>(filters)

    const { result: distributorsResult, loading: distributorsLoading } = useSubscription(SUBSCRIBE_DISTRIBUTORS, args)
    const { result: distributorsCountResult, loading: distributorCountLoading } = useSubscription(SUBSCRIBE_DISTRIBUTOR_COUNT, countWhere)

    const total = computed(() => distributorsCountResult.value?.distributor_aggregate?.aggregate?.count ?? 0)

    const distributors = computed<Distributor[]>(() => {
      // Ensure the page is not higher than the total number of pages
      const pageTotal = computed(() => Math.ceil(total.value / pagination.limit.value))
      pagination.page.value = Math.min(pageTotal.value, pagination.page.value)

      return mapDistributors(distributorsResult.value?.distributor as DistributorFragment[])
    })

    const loading = computed(() => distributorsLoading.value || distributorCountLoading.value)

    return {
      loading,
      distributors,
      limit: pagination.limit,
      page: pagination.page,
      columnOrders,
      zonesSelected,
      statusesSelected,
      textSearch,
      total,
    };
  }

  function queryReporting() {
    const { result: centerTotal, loading: centerLoading } = useSubscription(SUBSCRIBE_CENTERS_COUNT)
    const { result: patientTotal, loading: patientLoading } = useSubscription(SUBSCRIBE_PATIENTS_COUNT)

    const loading = computed(() => centerLoading.value || patientLoading.value)

    return {
      loading,
      centerTotal: computed(() => centerTotal.value?.center_aggregate?.aggregate?.count ?? "NA"),
      patientTotal: computed(() => patientTotal.value?.patient_aggregate?.aggregate?.count ?? "NA")
    }
  }

  const updateComments = (id: string, comments: Comment[]): Promise<void> => {
    const { mutate, onDone, onError } = useMutation(UPDATE_COMMENTS)
    const { t } = useI18n()

    return new Promise((resolve, reject) => {
      onDone((result) => {
        if (result.errors) {
          console.error(result.errors)
          reject(t('common.internal-error'))
        } else {
          resolve();
        }
      })

      onError((e) => reject(handleErrors(e)))

      mutate({
        id,
        comments: JSON.stringify(comments),
      })
    })
  }

  const addDistributor = (distributor: DistributorForm): Promise<string> => {
    const { mutate, onDone, onError } = useMutation(ADD_DISTRIBUTOR)

    const { t } = useI18n()

    return new Promise<string>((resolve, reject) => {
      onDone((result) => {
        if (result.errors) {
          console.error(result.errors)
          reject(t('common.internal-error'))
        } else {
          const distributor = useFragment(DistributorFragmentDoc, result.data?.insert_distributor_one)
          resolve(distributor?.identifier ?? '');
        }
      })
      onError((e) => reject(handleErrors(e)))
      mutate({
        ...distributor,
        comments: JSON.stringify(distributor.comments),
        accounts: distributor.users.map((user) => ({
          id: user.accountId ? user.accountId : user.id,
          distributor_id: distributor.id,
          user_id: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
        })),
        users: distributor.users.map((user) => ({
          id: user.id,
          email: user.email,
          phoneNumber: user.phone,
          locale: user.language || "en",
          defaultRole: 'distributor',
          disabled: user.disabled,
        })),
      })
    })
  }

  async function getDistributor(id: string) {
    const { onResult, onError } = useQuery(QUERY_DISTRIBUTOR, { id })

    return new Promise<Distributor>((resolve, reject) => {
      onResult((result) => {
        if (result.loading) return

        const distributor = mapDistributor(result.data?.distributor_by_pk as DistributorFragment)
        resolve(distributor)
      })

      onError(() => reject())
    })
  }

  const updateDistributor = async (distributor: DistributorForm): Promise<void> => {
    const distributorInDb = await getDistributor(distributor.id!!)
    
    const { mutate, onDone, onError } = useMutation(UPDATE_DISTRIBUTOR)
    const { t } = useI18n()

    const usersToDelete = distributorInDb.users.filter((user) => !distributor.users.find((u) => u.id === user.id))
    const usersToAdd = distributor.users.filter((user) => !distributorInDb.users.find((u) => u.id === user.id))
    const usersToUpdate = distributor.users.filter((user) => distributorInDb.users.find((u) => u.id === user.id))

    return await new Promise((resolve, reject) => {
      onDone((result) => {
        if (result.errors) {
          console.error(result.errors)
          reject(t('common.internal-error'))
        } else {
          distributorRefetchCallback()
          resolve();
        }
      })
      onError((e) => reject(handleErrors(e)))
      mutate({
        ...distributor,
        comments: JSON.stringify(distributor.comments),
        accountsToUpdate: usersToUpdate.map((user) => ({
          _set: {
            firstname: user.firstname,
            lastname: user.lastname,
          },
          where: { id: { _eq: user.accountId ? user.accountId : user.id } },
        })),
        accountsToAdd: usersToAdd.map((user) => ({
          id: user.accountId ? user.accountId : user.id,
          distributor_id: distributor.id,
          user_id: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
        })),
        usersToUpdate: usersToUpdate.map((user) => ({
          _set: {
            email: user.email,
            defaultRole: 'distributor',
            phoneNumber: user.phone,
            locale: user.language || "en",
            displayName: "",
            disabled: user.disabled,
          },
          where: { id: { _eq: user.id } },
        })),
        usersToAdd: usersToAdd.map((user) => ({
          id: user.id,
          email: user.email,
          phoneNumber: user.phone,
          locale: user.language || "en",
          displayName: "",
          defaultRole: 'distributor',
          disabled: user.disabled,
        })),
        accountsToDelete: usersToDelete.map((user) => user.accountId ? user.accountId : user.id),
      })
    })
  }

  return {
    queryAllDistributors,
    queryDistributorInfos,
    queryDistributor,
    queryDistributors,
    updateDistributor,
    addDistributor,
    updateComments,
    queryDistributorList,
    useStatus,
    queryReporting,
  }
}
