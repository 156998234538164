<template>
  <div>
    <v-table hover>
      <thead>
        <tr>
          <th v-for="{ title, key, filter } of headers" :id="key" @click="filter && toggleOrder(key)"
            class="text-left text-typography-700 text-body-large" :class="filter !== undefined ? 'cursor-pointer' : ''">
            <v-icon v-if="selectedColumns === key && order === 'asc'" class="mr-2" size="24"
              color="primary-100">mdi-sort-alphabetical-ascending</v-icon>
            <v-icon v-if="selectedColumns === key && order === 'desc'" class="mr-2" size="24"
              color="primary-100">mdi-sort-alphabetical-descending</v-icon>
            {{ title }}
          </th>
        </tr>
      </thead>
      <slot name="prepend-tbody"></slot>
      <tbody v-if="!isEmpty">
        <slot v-if="!loading"> </slot>
      </tbody>
    </v-table>
    <div v-if="loading" class="d-flex w-full justify-center bg-primary-700">
      <v-progress-circular class="ma-2" indeterminate model-value="20" />
    </div>
    <div v-if="!loading && !onePage" class="d-flex w-full justify-end bg-primary-700 rounded-b-lg">
      <v-pagination v-if="!loading && !isEmpty && !disablePagination" :length="pages" :model-value="currentPage"
        @update:model-value="$emit('update:currentPage', $event)" :total-visible="6" />
    </div>
    <div v-if="!loading && isEmpty" class="py-5 text-center">
      <p>{{ $t("common.no-data") }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Order_By } from "@/gql/graphql";
import { AnyOrder } from "@/utils/order";
import { computed, ref } from "vue";

const emit = defineEmits<{
  selectAll: [boolean];
  "update:currentPage": [number];
  "update:filters": [AnyOrder]
}>();

const props = defineProps<{
  headers: { title: string; key: string; filter?: string }[];
  loading?: boolean;
  limit?: number;
  currentPage?: number;
  total?: number;
  disablePagination?: boolean;
  defaultSelected?: { key: string; order: Order_By };
}>();

const selectedColumns = ref<string | null>(props.defaultSelected?.key || null);
const order = ref<Order_By>(props.defaultSelected?.order || Order_By.Asc);

const keyToFilterObject = (key: string, order: Order_By): AnyOrder => {
  const firstKey = key.split(".")[0];
  const rest = key.split(".").slice(1).join(".");

  if (rest === "") return { [firstKey]: order };

  return {
    [firstKey]: keyToFilterObject(rest, order),
  };
};

function toggleOrder(column: string) {
  if (column === "") return;

  if (selectedColumns.value === column) {
    order.value = order.value === Order_By.Asc ? Order_By.Desc : Order_By.Asc;
  } else {
    selectedColumns.value = column;
    order.value = Order_By.Asc
  }

  const selectedHeader = props.headers.find(
    (h) => h.key === selectedColumns.value
  );

  if (selectedHeader?.filter) {
    const filters = keyToFilterObject(selectedHeader?.filter, order.value);
    emit("update:filters", filters);
  } else {
    emit("update:filters", {});
  }
}

const isEmpty = computed(() => props.total === 0);


const pages = computed(() =>
  props.total && props.limit ? Math.floor(props.total / props.limit) + (props.total % props.limit === 0 ? 0 : 1) : 1
);

const onePage = computed(() => pages.value <= 1);
</script>
