<template>
  <v-navigation-drawer app class="bg-background-700 px-8 py-12" :model-value="true" :permanent="true" :order="100">
    <template v-slot:prepend>
      <v-img :src="DarkUrl" alt="Logo" width="100%" class="mb-8 mx-auto" v-if="isDark" />
      <v-img :src="LightUrl" alt="Logo" width="100%" class="mb-8 mx-auto" v-else />
    </template>

    <template v-slot>
      <v-divider :thickness="2" color="primary-300" class="mt-3"></v-divider>

      <p class="text-typography-700 my-8">Menu</p>

      <NavbarLink :text="$t('center.title.profile')" icon="mdi-hospital-box-outline" to="/center-profile" class="mb-4"
        entry="center-profile" v-if="authStore.isA([Role.CENTER])" />

      <NavbarLink :text="$t('patient.title.profile')" icon="lucide:briefcase-medical" to="/patient-profile" class="mb-4"
        entry="patient-profile" v-if="authStore.isA([Role.PATIENT])" />

      <NavbarLink :text="$t('distributor.title.profile')" icon="lucide:boxes" to="/distributor-profile" class="mb-4"
        entry="distributor-profile" v-if="authStore.isA([Role.DISTRIBUTOR])" />

      <NavbarLink :text="$t('distributors.title')" to="/distributors" class="mb-4" entry="distributor"
        v-if="authStore.isA([Role.SUPER_ADMIN])" icon="lucide:boxes" />

      <NavbarLink :text="$t('centers.title')" icon="mdi-hospital-box-outline" to="/centers" class="mb-4" entry="center"
        v-if="authStore.isA([Role.SUPER_ADMIN, Role.DISTRIBUTOR])" />
      <NavbarLink :text="$t('operators.title')" icon="mdi-stethoscope" to="/operators" class="mb-4" entry="operator"
        v-if="authStore.isA([Role.SUPER_ADMIN, Role.CENTER])" />
      <NavbarLink :text="$t('patients.title')" icon="mdi-account-group-outline" to="/patients" class="mb-4"
        v-if="authStore.isA([Role.SUPER_ADMIN, Role.CENTER, Role.DISTRIBUTOR, Role.OPERATOR])" entry="patient" />

      <v-divider :thickness="2" color="primary-300" class="mb-4"
        v-if="authStore.isA([Role.SUPER_ADMIN, Role.DISTRIBUTOR])" />

      <NavbarLink :text="$t('technicians.title')" icon="mdi-wrench-outline" to="/technicians" class="mb-4"
        entry="technician" v-if="authStore.isA([Role.SUPER_ADMIN, Role.DISTRIBUTOR])" />

      <NavbarLink :text="$t('machines.title')" icon="mdi-server-outline" to="/machines" class="mb-4" entry="machine"
        v-if="authStore.isA([Role.SUPER_ADMIN, Role.DISTRIBUTOR, Role.TECHNICIAN])" />

      <NavbarLink :text="$t('versions.title')" icon="mdi-file-document-outline" to="/versions" class="mb-4"
        entry="version" v-if="authStore.isA([Role.SUPER_ADMIN, Role.TECHNICIAN])" />

      <v-divider :thickness="2" color="primary-300" v-if="displayDevTools" class="mb-4" />

      <NavbarLink v-if="displayDevTools" :text="$t('components.title')" icon="mdi-package-variant-closed"
        to="/components" class="mb-4" entry="component" />

      <v-divider :thickness="2" color="primary-300" class="mb-4" />

      <NavbarLogout class="mb-4" />

      <div class="bg-background-500 rounded-lg pa-3 align-center d-flex justify-start pointer"
        :class="profileSelected ? 'border-on' : 'border-off'" v-if="userReady" @click="$router.push('/profile')">
        <div class="flex-grow-0 pr-3">
          <v-avatar color="brown" size="small">
            <span>{{ firstLetter }}</span>
          </v-avatar>
        </div>
        <div class="align-start d-flex flex-column">
          <div class="display-name">{{ displayName }}</div>
          <div class="text-subtitle-2 text-typography-700">
            {{ role }}
          </div>
        </div>
      </div>

      <ThemeSwitcher v-model="isDark" @click="toggleTheme" class="mt-4" />
      <v-select v-if="displayDevTools" :items="languages" outlined dense class="mt-4" v-model="$i18n.locale"
        hide-details />
    </template>
  </v-navigation-drawer>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useTheme } from 'vuetify'
import { useStorage } from '@vueuse/core'

import ThemeSwitcher from '@/components/atoms/ThemeSwitcher.vue';
import NavbarLink from '@/components/atoms/NavbarLink.vue';
import NavbarLogout from '@/components/atoms/NavbarLogout.vue';

import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useI18n } from '@/plugins/i18n';
import { Role } from '@/store/auth/models';

import DarkUrl from '@/../assets/title-logo-dark.svg?url'
import LightUrl from '@/../assets/title-logo-light.svg?url'

const router = useRouter();
const userStore = useUserStore();
const authStore = useAuthStore();
const theme = useTheme()

const { languages, t } = useI18n()
const themeStore = useStorage('theme', 'lightTheme')

const displayDevTools = import.meta.env.DEV

function toggleTheme() {
  themeStore.value = themeStore.value === 'lightTheme' ? 'darkTheme' : 'lightTheme'
  theme.global.name.value = themeStore.value
}

const profileSelected = computed(() =>
  router.currentRoute.value.meta?.selectedNavbarEntry === 'profile'
);

const displayName = ref<string>('')
const firstLetter = ref<string>('')
const userReady = ref(false)
const role = ref<string>('')

userStore.watchUser((user) => {
  displayName.value = `${user?.firstname} ${user?.lastname}`
  firstLetter.value = user?.firstname?.charAt(0) || ''
  role.value = user ? t(`role.${user?.defaultRole}`) : ''

  if (user) userReady.value = true
})

const isDark = computed(() => theme.global.current.value.dark)
</script>
<style lang="scss">
.pointer {
  cursor: pointer;
}

.border-on {
  border: 2px solid rgb(var(--v-theme-primary-900));
}

.border-off {
  border: 2px solid rgb(var(--v-theme-background-500));
}

.display-name {
  display: block;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
}
</style>
