import { ToastType, useToastStore } from "@/store/toast";
import { RouteRecordRaw } from "vue-router";

export default [
  // REDIRECTS
  {
    path: "/redirect/email-updated",
    redirect: _ => {
      const toastStore = useToastStore();
      toastStore.addToast("profile.email-updated", ToastType.SUCCESS);
      return "/profile";
    }
  },
  // ROUTES
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
    meta: { title: "sign-in.title", hideDrawer: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: { title: "forgot-password.title", hideDrawer: true },
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () => import("@/views/NewPassword.vue"),
    meta: { title: "new-password.title", hideDrawer: true },
  },
  {
    path: "/components",
    name: "Components",
    component: () => import("@/views/Components.vue"),
    meta: { title: "sign-in.title", hideDrawer: false, selectedNavbarEntry: "component" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    meta: { title: "not-found.title", hideDrawer: true },
  }
] as RouteRecordRaw[];

