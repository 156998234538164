import { TechnicianFragment, UserFieldsFragmentDoc } from '@/gql/graphql';
import { Technician } from './models';
import { useFragment } from '@/gql';

export function mapTechnicians(data: TechnicianFragment[] | null): Technician[] {
  if (!data) throw new Error('Technicians data is null')

  return data.map(mapTechnician)
}

export function mapTechnician(data?: TechnicianFragment | null): Technician {
  if (!data) throw new Error('Technician data is null')

  const userData = data.accounts?.user
  if (!userData) throw new Error('User data is null')

  const user = useFragment(UserFieldsFragmentDoc, userData)
  if (!user) throw new Error('Primary account is null')

  return {
    id: data.id,
    user_id: user.id,
    firstname: user.account?.firstname || '',
    lastname: user.account?.lastname || '',
    phone: user.phoneNumber || '',
    email: user.email,
    secondaryEmail: data.secondary_email || '',
    secondaryPhone: data.secondary_phone || '',
    role: data.role || '',
    distributorId: data.distributor_id || '',
  }
}

