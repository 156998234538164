import { Action_Reporting_Bool_Exp, Action_Reporting_Order_By, Order_By } from "@/gql/graphql";
import { computed, ref } from "vue";

export function useActionReportingFilters() {
    const columnOrders = ref<Action_Reporting_Order_By>({ timestamp: Order_By.Desc })

    const textFilters = computed<null | Action_Reporting_Bool_Exp>(() =>
        null
    );

    const filters = computed<Action_Reporting_Bool_Exp>(() => {
        const filters = [
            textFilters.value,
            { action_role: { _neq: 'version_center' }}
        ].filter((f): f is NonNullable<Action_Reporting_Bool_Exp> => f !== null)

        return filters.length > 0 ? { _and: filters } : {}
    })

    return {
        columnOrders,
        filters,
    }
}