import { graphql } from "@/gql";

export const SUBSCRIBE_USER = graphql(`
subscription SubscribeUser($id: uuid!) {
  user(id: $id) {
    ...UserFields
  }
}
`)

export const SUBSCRIBE_PATIENT = graphql(`
subscription SubscribePatient($id: uuid!) {
  user(id: $id) {
    ...UserFields
    account {
      patient {
        ...Patient
      }
    }
  }
}
`)

export const SUBSCRIBE_OPERATOR = graphql(`
subscription SubscribeOperator($id: uuid!) {
  user(id: $id) {
    ...UserFields
    account {
      operator {
        ...Operator
      }
    }
  }
}
`)

export const SUBSCRIBE_TECHNICIAN = graphql(`
subscription SubscribeTechnician($id: uuid!) {
  user(id: $id) {
    ...UserFields
    account {
      technician {
        ...Technician
      }
    }
  }
}
`)

export const SUBSCRIBE_DISTRIBUTOR = graphql(`
subscription SubscribeDistributor($id: uuid!) {
  user(id: $id) {
    ...UserFields
    account {
      distributor {
        ...Distributor
      }
    }
  }
}
`)

export const SUBSCRIBE_CENTER = graphql(`
subscription SubscribeCenter($id: uuid!) {
  user(id: $id) {
    ...UserFields
    account {
      center {
        ...Center
      }
    }
  }
}
`)

export const DELETE_USER = graphql(`
mutation DeleteUser($accountId: uuid!) {
  delete_account_by_pk(id: $accountId) {
    id
  }

  updateUsers(where: {account: {id: {_eq: $accountId}}}, _set: { disabled: true }) {
    affected_rows
  }
}
`)

export const UPDATE_USER = graphql(`
mutation UpdateUser(
  $id: uuid!
  $firstname: String!
  $lastname: String!
  $pseudo: String!
  $phone: String!
  $locale: String!
) {
  updateUser(pk_columns: {id: $id}, _set: {
    displayName: $pseudo
    phoneNumber: $phone
    locale: $locale
  }) {
    id
    ...UserFields
  }

  update_account_by_pk(pk_columns: {id: $id}, _set: {
    firstname: $firstname
    lastname: $lastname
  }) {
    user {
      ...UserFields
    }
  }
}
`)
