<template>
  <div>
    <div ref="editorRef"></div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { useI18n } from "@/plugins/i18n"

const { t, locale } = useI18n();
const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits<{
  "update:modelValue": [string];
}>();

const editorRef = ref<HTMLElement>();
const editor = ref<Quill>();

// Set model value
watch(props, ({ modelValue }) => {
  if (!editor.value) return;
  if (modelValue === editor.value.root.innerHTML) return;

  editor.value.root.innerHTML = modelValue;
});

onMounted(() => {
  if (!editorRef.value) return;

  // Delete element first 
  editorRef.value.innerHTML = "";

  editor.value = new Quill(editorRef.value, {
    modules: {
      toolbar: [
        "bold",
        "italic",
        { 'header': 1 },
        { 'header': 2 },
        'blockquote',
        "link",
        { 'list': 'bullet' },
        { 'list': 'ordered' },
      ],
    },
    theme: "snow",
    placeholder: t("comments.placeholder")
  });

  watch(locale, () => {
    editor.value?.root.setAttribute("data-placeholder", t("comments.placeholder"))
  })

  editor.value.root.innerHTML = props.modelValue;
  editor.value.on("text-change", update);
})

function update() {
  emit("update:modelValue", editor.value?.getText() ? editor.value?.root.innerHTML : "");
}
</script>
<style lang="scss">
$icon-color: #858a91;
$background-color: rgb(var(--v-theme-background-500));
$text-color: rgb(var(--v-theme-typography-900));
$placeholder-color: rgb(var(--v-theme-typography-700));
$border-radius: 8px;

.ql-toolbar.ql-snow {
  background-color: $background-color;
  border: none;
  border-radius: $border-radius;

  margin-bottom: 8px;

  .ql-stroke {
    fill: none;
    stroke: $icon-color;
  }

  .ql-fill {
    fill: $icon-color;
    stroke: none;
  }

  .ql-picker {
    color: $icon-color;
  }
}

.ql-container.ql-snow {
  background-color: $background-color;
  border: none;
  border-radius: $border-radius;

  .ql-editor {
    color: $text-color;

    &::before {
      color: $placeholder-color;
    }
  }
}
</style>
