<template>
  <div class="complexity-bar mt-2 w-100 d-flex flex-row">
    <div v-for="_, i in range" :class="getChunkClass(i, i === 0)" :key="i">
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  complexity: number,
  max: number
}>()

let range = Array.from(Array(props.max).keys())

function getChunkClass(i: number, end: boolean) {
  return {
    'chunk': true,
    'chunk--active': i < props.complexity,
    'flex-grow-1': true,
    'ml-1': !end
  }
}
</script>
<style scoped lang="scss">
.complexity-bar {
  height: 1px;

  .chunk {
    height: 100%;
    background-color: #3a3a3a;
    transition: background-color 0.5s;

    &.chunk--active {
      background-color: #4DEE5D;
    }
  }
}
</style>
