<template>
  <v-menu :close-on-content-click="false" :offset="24">
    <template v-slot:activator="{ props }">
      <v-btn-top-bar v-bind="props" :class="class" append-icon="lucide:filter">
        {{ $t('common.filters') }}
      </v-btn-top-bar>
    </template>
    <div class="d-flex flex-row pa-0 ma-0 rounded-lg bg-background-700 elevation-2">
      <div class="d-flex flex-column pa-4 pb-0">
        <v-btn v-for="group in state" rounded="lg" :key="group.id" @click="changeGroup(group.id)"
          append-icon="mdi-arrow-right" class="mb-4 text-none" :class="group.selected ? 'bg-primary-900' : ''"
          elevation="0">
          {{ group.title }}
        </v-btn>
      </div>
      <div :style="{
        borderLeft: '1px solid rgb(var(--v-theme-background-300))',
        width: '1px',
        margin: '1rem',
      }"></div>
      <div class=" d-flex flex-column pa-4">
        <v-checkbox v-for="checkbox in currentCheckboxes" :key="checkbox.id" :label="checkbox.title" density="compact"
          hide-details :model-value="checkbox.value" @click="toggleCheckbox(checkbox.id)" />
      </div>
    </div>
  </v-menu>
</template>
<script setup lang="ts">
import { ref, computed, watch, toRefs } from "vue";

interface FilterValue {
  title: string;
  value: string;
}

interface Filter {
  id: string;
  title: string;
  values: FilterValue[];
}

interface Checkbox {
  id: string;
  title: string;
  value: boolean;
}

const props = defineProps<{
  filters: Filter[];
  class?: string;
}>();

const emit = defineEmits<{
  "update": [string, string[]]
}>();

const { filters } = toRefs(props);

function initialState(filters: Filter[]) {
  return filters.map((val, i) => ({
    id: val.id,
    selected: i === 0,
    title: val.title,
    checkboxes: val.values.map((item) => ({
      id: item.value,
      title: item.title,
      value: false,
    })),
  }))
}

const state = ref<CheckboxGroup[]>(initialState(filters.value));
watch(filters, (newFilters) => {
  state.value = initialState(newFilters);
}, { deep: true, immediate: true });


enum ActionTypes {
  CHANGE_FILTER,
  TOGGLE_CHECKBOX,
}

interface CheckboxGroup {
  id: string;
  title: string;
  selected: boolean;
  checkboxes: Checkbox[];
}

function checkboxReducer(state: Checkbox, action: ActionTypes, payload: any): Checkbox {
  switch (action) {
    case ActionTypes.TOGGLE_CHECKBOX:
      return state.id === payload ? { ...state, value: !state.value } : state;
    case ActionTypes.CHANGE_FILTER:
      return { ...state, value: false };
  }
}

function checkboxesReducer(state: Checkbox[], action: ActionTypes, payload: any): Checkbox[] {
  return state.map((checkbox) => checkboxReducer(checkbox, action, payload));
}

function groupReducer(state: CheckboxGroup, action: ActionTypes, payload: any): CheckboxGroup {
  switch (action) {
    case ActionTypes.TOGGLE_CHECKBOX:
      return state.selected ? { ...state, checkboxes: checkboxesReducer(state.checkboxes, action, payload) } : state;
    case ActionTypes.CHANGE_FILTER:
      return {
        ...state,
        checkboxes: checkboxesReducer(state.checkboxes, action, payload),
        selected: state.id === payload,
      };
  }
}

function stateReducer(state: CheckboxGroup[], action: ActionTypes, payload: any): CheckboxGroup[] {
  return state.map((group) => groupReducer(group, action, payload));
}

function changeGroup(groupId: string) {
  state.value = stateReducer(state.value, ActionTypes.CHANGE_FILTER, groupId);
}

function toggleCheckbox(checkBoxId: string) {
  state.value = stateReducer(state.value, ActionTypes.TOGGLE_CHECKBOX, checkBoxId)
}

const currentCheckboxes = computed<Checkbox[]>(() => state.value.find((group) => group.selected)?.checkboxes || []);

watch(state, (newState) => {
  const selectedGroup = newState.find((group) => group.selected);
  if (!selectedGroup) return;

  const selectedCheckboxes = selectedGroup.checkboxes
    .filter((checkbox) => checkbox.value)
    .map((checkbox) => checkbox.id);

  emit("update", selectedGroup.id, selectedCheckboxes);
}, { deep: true })
</script>
