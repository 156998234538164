<template>
  <div class="py-4 px-6 mb-4 d-flex flex-row bg-background-700 rounded-lg" :id="props.id" :class="config.classes">
    <p class="font-weight-medium text-h4">{{ props.message }}</p>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import { ToastType } from '@/store/toast';

const props = defineProps<{
  message: string;
  id?: string;
  type: ToastType;
}>();

interface Config {
  classes: string;
}

const configs: Record<ToastType, Config> = {
  [ToastType.SUCCESS]: {
    classes: "text-tag-1",
  },
  [ToastType.ERROR]: {
    classes: "text-suppression-900",
  },
};

const config = computed(() => configs[props.type]);
</script>
