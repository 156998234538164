<template>
  <NavbarEntry :text="text" :selected="selected" @click="navigate" :class="props.class">
    <slot v-if="!icon"></slot>
    <v-icon v-else class="text-body-1">
      {{ icon }}
    </v-icon>
  </NavbarEntry>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import NavbarEntry from '@/components/atoms/NavbarEntry.vue';
import { useAuthStore } from '@/store/auth';

defineSlots<{
  default: SVGAElement;
}>();

const props = defineProps<{
  to: string;
  text: string;
  icon?: string;
  class?: string;
  entry: string;
}>();

const router = useRouter();
const auth = useAuthStore();

const selected = computed(() =>
  router.currentRoute.value.meta?.selectedNavbarEntry === props.entry ||
  router.currentRoute.value.meta?.selectedNavbarEntry === 'home' && auth.getDashboard() === props.entry
);

const navigate = async () => await router.push(props.to);
</script>
