<template>
  <div class="banner-container h-100">
    <img :src="DarkUrl" class="logo" width="400" v-if="isDark" />
    <img :src="LightUrl" class="logo" width="400" v-else />
    <div class="content">
      <slot></slot>
    </div>
    <div class="banner" :class="bannerClass"></div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
const theme = useTheme()
const isDark = computed(() => theme.global.current.value.dark)

import DarkUrl from '@/../assets/title-logo-dark.svg?url'
import LightUrl from '@/../assets/title-logo-light.svg?url'

const bannerClass = computed(() => isDark.value ? 'banner-dark' : 'banner-light')

</script>
<style lang="scss">
.banner-container {
  display: flex;
  flex-direction: row;

  .banner {
    flex: 1;
    background-position: center;
    background-repeat: no-repeat;
  }

  .banner-light {
    background-image: url('/assets/banner-light.png');
  }

  .banner-dark {
    background-image: url('/assets/banner-dark.png');
  }

  .content {
    flex: 1;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  padding: 60px;
}
</style>
