<template>
  <div>
    <div class="d-flex flex-row justify-space-between">
      <p>{{ $t('comments.title') }}</p>
      <a class="text-background-100" v-if="modelValue.length <= 1 && readonly">
        {{ $tc('comments.see-all', modelValue.length) }}
      </a>
      <a class="text-primary-900 cursor-pointer" v-if="modelValue.length > 1 && readonly" @click="drawerOpen = true">
        {{ $tc('comments.see-all', modelValue.length) }}
      </a>
      <a class="text-primary-900 cursor-pointer" v-if="!readonly" @click="drawerOpen = true">
        {{ $tc('comments.edit-all', modelValue.length) }}
      </a>
    </div>
    <v-divider class="my-4" v-if="!readonly"></v-divider>
    <TextEditor v-if="!readonly" v-model="textContent" class="mb-4" />
    <v-btn-primary @click="addComment" class="w-100" v-if="!readonly">
      {{ $t('common.add') }}
    </v-btn-primary>
    <v-divider class="my-4"></v-divider>
    <TextView v-if="modelValue.length !== 0" :key="modelValue.length" :comment="modelValue[modelValue.length - 1]"
      :readonly="true" />
    <comment-drawer v-model:isOpen="drawerOpen" :modelValue="modelValue"
      @update:modelValue="emit('update:modelValue', $event)" :readonly="readonly" />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import { Comment } from "@/services/comments/models";

import TextView from "@/components/atoms/TextView.vue";
import TextEditor from "@/components/atoms/TextEditor.vue";

const props = defineProps<{
  modelValue: Comment[]
  readonly: boolean
}>();

const emit = defineEmits<{
  "update:modelValue": [Comment[]];
}>();

const textContent = ref<string>("");
const drawerOpen = ref(false);

function addComment() {
  if (textContent.value === "") {
    return;
  }

  const newComment: Comment = {
    id: uuidv4(),
    date: new Date(),
    content: textContent.value,
  };

  emit("update:modelValue", [...props.modelValue, newComment]);
}
</script>